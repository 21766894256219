import styled from 'styled-components';

import { Incrementor } from 'components/incrementor';

export const BottomContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-block-start: 0.625rem;
`;

export const Badge = styled.div<{ $backgroundColor: string }>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  font-size: 0.75rem;
  line-height: 0.875rem;
  text-align: center;
  font-family: ${Styles.fontFamily.brand};
  color: ${Styles.color.white};
  width: fit-content;
  min-width: 4rem;
  border-radius: 5px;
  padding-block-start: 0.125rem;
  padding-block-end: 0.25rem;
  padding-inline: 0;
`;

export const StyledIncrementor = styled(Incrementor)`
  flex-grow: 0;
  margin-inline-start: 0.625rem;
  line-height: 1;

  button {
    padding: 0;

    svg {
      width: auto;
      height: 1.25rem;
    }
  }

  button:not([disabled]) {
    svg {
      border-color: ${Styles.color.black};

      path:last-child {
        fill: ${Styles.color.black};
      }
    }
  }
`;

export const BadgeCrownPoints = styled.div`
  padding: 0 0.5rem;
  margin-block-start: -1px;
`;
