/**
 * AUTOGENERATED - DO NOT EDIT DIRECTLY
 *
 * Please see "scripts/regionalization/README.md" for how to modify
 */

import { IsoCountryCode2 } from '@rbilabs/intl';

import { IPoints } from '@rbi-ctg/frontend';

type TSupportedCountry = Exclude<IsoCountryCode2, 'US' | 'CA'>;

export const mapDefaults: Record<TSupportedCountry | 'default', IPoints> = {
  /**
   * (AE)
   */
  [IsoCountryCode2.AE]: {
    lat: 23.424076,
    lng: 53.847818,
    zoom: 4,
    maxZoom: 12,
  },
  /**
   * (AQ)
   */
  [IsoCountryCode2.AQ]: {
    lat: -75.250973,
    lng: -0.071389,
    zoom: 4,
    maxZoom: 12,
  },
  /**
   * (AR)
   */
  [IsoCountryCode2.AR]: {
    lat: -34.583333333333336,
    lng: -58.666667,
    zoom: 4,
    maxZoom: 12,
  },
  /**
   * (AT)
   */
  [IsoCountryCode2.AT]: {
    lat: 48.2,
    lng: 16.366667,
    zoom: 4,
    maxZoom: 12,
  },
  /**
   * (AU)
   */
  [IsoCountryCode2.AU]: {
    lat: -25.274398,
    lng: 133.775136,
    zoom: 4,
    maxZoom: 12,
  },
  /**
   * (BH)
   */
  [IsoCountryCode2.BH]: {
    lat: 25.930414,
    lng: 50.637772,
    zoom: 4,
    maxZoom: 12,
  },
  /**
   * (BR)
   */
  [IsoCountryCode2.BR]: {
    lat: 25.930414,
    lng: 50.637772,
    zoom: 4,
    maxZoom: 12,
  },
  /**
   * (CL)
   */
  [IsoCountryCode2.CL]: {
    lat: -33.45,
    lng: -70.666667,
    zoom: 4,
    maxZoom: 12,
  },
  /**
   * (CH)
   */
  [IsoCountryCode2.CH]: {
    lat: 46.801111,
    lng: 8.226667,
    zoom: 4,
    maxZoom: 12,
  },
  /**
   * (CZ)
   */
  [IsoCountryCode2.CZ]: {
    lat: 50.083333333333336,
    lng: 14.466667,
    zoom: 4,
    maxZoom: 12,
  },
  /**
   * (DE)
   */
  [IsoCountryCode2.DE]: {
    lat: 51.1633733,
    lng: 10.44768691,
    zoom: 4,
    maxZoom: 12,
  },
  /**
   * (DO)
   */
  [IsoCountryCode2.DO]: {
    lat: 18.7357,
    lng: 70.1627,
    zoom: 4,
    maxZoom: 12,
  },
  /**
   * (ES)
   */
  [IsoCountryCode2.ES]: {
    lat: 40.4,
    lng: -3.683333,
    zoom: 4,
    maxZoom: 12,
  },
  /**
   * (EG)
   */
  [IsoCountryCode2.EG]: {
    lat: 26.820553,
    lng: 30.802498,
    zoom: 4,
    maxZoom: 12,
  },
  /**
   * (FR)
   */
  [IsoCountryCode2.FR]: {
    lat: 48.8566,
    lng: 2.3522,
    zoom: 4,
    maxZoom: 12,
  },
  /**
   * (GB)
   */
  [IsoCountryCode2.GB]: {
    lat: 54.00366,
    lng: -2.547855,
    zoom: 4,
    maxZoom: 12,
  },
  /**
   * (IE)
   */
  [IsoCountryCode2.IE]: {
    lat: 53.41291,
    lng: -8.24389,
    zoom: 4,
    maxZoom: 12,
  },
  /**
   * (MU)
   */
  [IsoCountryCode2.IT]: {
    lat: 45.4637,
    lng: 9.1885,
    zoom: 4,
    maxZoom: 12,
  },
  /**
   * (KR)
   */
  [IsoCountryCode2.KR]: {
    lat: 37.5326,
    lng: 127.024612,
    zoom: 4,
    maxZoom: 12,
  },
  /**
   * (KW)
   */
  [IsoCountryCode2.KW]: {
    lat: 29.31166,
    lng: 47.481766,
    zoom: 4,
    maxZoom: 12,
  },
  /**
   * (KY)
   */
  [IsoCountryCode2.KY]: {
    lat: 19.3133,
    lng: 81.2546,
    zoom: 4,
    maxZoom: 12,
  },
  /**
   * (MA)
   */
  [IsoCountryCode2.MA]: {
    lat: 31.791702,
    lng: -7.09262,
    zoom: 4,
    maxZoom: 12,
  },
  /**
   * (MU)
   */
  [IsoCountryCode2.MU]: {
    lat: 20.3484,
    lng: 57.5522,
    zoom: 4,
    maxZoom: 12,
  },
  /**
   * (MX)
   */
  [IsoCountryCode2.MX]: {
    lat: 19.433333333333334,
    lng: -99.133333,
    zoom: 4,
    maxZoom: 12,
  },
  /**
   * (MY)
   */
  [IsoCountryCode2.MY]: {
    lat: 4.210484,
    lng: 101.975766,
    zoom: 4,
    maxZoom: 12,
  },
  /**
   * (NL)
   */
  [IsoCountryCode2.NL]: {
    lat: 52.35,
    lng: 4.916667,
    zoom: 4,
    maxZoom: 12,
  },
  /**
   * (NZ)
   */
  [IsoCountryCode2.NZ]: {
    lat: -36.848461,
    lng: 174.763336,
    zoom: 4,
    maxZoom: 12,
  },
  /**
   * (OM)
   */
  [IsoCountryCode2.OM]: {
    lat: 21.512583,
    lng: 55.923255,
    zoom: 4,
    maxZoom: 12,
  },
  /**
   * (PH)
   */
  [IsoCountryCode2.PH]: {
    lat: 12.879721,
    lng: 121.774017,
    zoom: 4,
    maxZoom: 12,
  },
  /**
   * (PR)
   */
  [IsoCountryCode2.PR]: {
    lat: 18.466666666666665,
    lng: -66.116667,
    zoom: 4,
    maxZoom: 12,
  },
  /**
   * (PT)
   */
  [IsoCountryCode2.PT]: {
    lat: 38.7223,
    lng: 9.1393,
    zoom: 4,
    maxZoom: 12,
  },
  /**
   * (PL)
   */
  [IsoCountryCode2.PL]: {
    lat: 52.25,
    lng: 21,
    zoom: 4,
    maxZoom: 12,
  },
  /**
   * (QA)
   */
  [IsoCountryCode2.QA]: {
    lat: 25.354826,
    lng: 51.183884,
    zoom: 4,
    maxZoom: 12,
  },
  /**
   * (RO)
   */
  [IsoCountryCode2.RO]: {
    lat: 44.43333333333333,
    lng: 26.1,
    zoom: 4,
    maxZoom: 12,
  },
  /**
   * (SA)
   */
  [IsoCountryCode2.SA]: {
    lat: 23.885942,
    lng: 45.079162,
    zoom: 4,
    maxZoom: 16,
  },
  /**
   * (SG)
   */
  [IsoCountryCode2.SG]: {
    lat: 1.2833333333333332,
    lng: 103.85,
    zoom: 4,
    maxZoom: 12,
  },
  /**
   * (TW)
   */
  [IsoCountryCode2.TW]: {
    lat: 25.033333333333335,
    lng: 121.516667,
    zoom: 4,
    maxZoom: 12,
  },
  /**
   * (ZA)
   */
  [IsoCountryCode2.ZA]: {
    lat: 28.99346,
    lng: 25.07909,
    zoom: 4,
    maxZoom: 12,
  },
  default: {
    lat: 44.967243,
    lng: -103.771556,
    zoom: 4,
    maxZoom: 12,
  },
};
