import { useCallback, useRef, useState } from 'react';

import { useMutation } from '@apollo/client';
import { noop } from 'lodash';

import { FireOrderIn, IServerOrder } from '@rbi-ctg/menu';
import {
  CommitOrderDocument as CommitOrderDocumentFulFillment,
  GetOrderDocument as GetOrderDocumentFulfillment,
  GetUserOrdersDocument as GetUserOrdersDocumentFulfillment,
} from 'generated/graphql-gateway';
import {
  CommitOrderDocument,
  GetOrderDocument,
  GetUserOrdersDocument,
} from 'generated/rbi-graphql';
import { usePosVendor } from 'hooks/menu/use-pos-vendor';
import { useOrderStatus } from 'hooks/order-status';
import { ICommitOrderMutationResponse, ICommitOrderMutationVariables } from 'remote/queries/order';
import { useAuthContext } from 'state/auth';
import { useAuthGuestContext } from 'state/auth-guest';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useOrderContext } from 'state/order';
import { OrderSuccessFailureStatuses } from 'state/order/constants';
import { ServiceMode } from 'state/service-mode';
import { buildCommitDeliveryInput, buildCommitOrderInput } from 'utils/cart';
import { PerformanceMarks, setMark } from 'utils/timing';
import { PosVendors } from 'utils/vendor-config';

import { mapCardType } from './map-card-type';
import { ICommitOrderInput, IUseCommitOrderParams } from './types';

const ORDER_DURATION_ACTION = 'commit';
// default order status polling interval, in ms
const DEFAULT_POLL_INTERVAL = 1500;

export const useCommitOrder = ({
  onCompleted = noop,
  orderStatusPollInterval = DEFAULT_POLL_INTERVAL,
  rbiOrderId,
  skipOrderStatusPolling = true,
}: IUseCommitOrderParams) => {
  const [skipPolling, setSkipPolling] = useState(skipOrderStatusPolling);
  const { logOrderLatencyDuration, onCommitSuccess } = useOrderContext();
  const auth = useAuthContext();
  const authGuest = useAuthGuestContext();

  const commitOptions = useRef({} as ICommitOrderInput);
  const { vendor } = usePosVendor();
  const enableBackEndCommitOrder = useFlag(LaunchDarklyFlag.ENABLE_BACKEND_COMMIT_ORDER);
  const isPartnerOrder = vendor === PosVendors.PARTNER;

  const isFulfillmentServiceGetOrdersEnabled = useFlag(
    LaunchDarklyFlag.ENABLE_FULFILLMENT_SERVICE_GET_ORDER
  );
  const isFulfillmentServiceGetUserOrdersEnabled = useFlag(
    LaunchDarklyFlag.ENABLE_FULFILLMENT_SERVICE_GET_USER_ORDERS
  );

  const enableSkipRefetchRecentOrdersOnCommit = useFlag(
    LaunchDarklyFlag.ENABLE_SKIP_REFETCH_RECENT_ORDERS_ON_COMMIT
  );

  const refetchQueries: {
    query: any;
    variables?: any;
  }[] = [
    isFulfillmentServiceGetOrdersEnabled
      ? { query: GetOrderDocumentFulfillment, variables: { rbiOrderId } }
      : { query: GetOrderDocument, variables: { rbiOrderId } },
  ];

  // If flag is enabled, do not fetch recent orders right after an order is placed.
  if (!enableSkipRefetchRecentOrdersOnCommit) {
    const getUserOrdersQuery = isFulfillmentServiceGetUserOrdersEnabled
      ? { query: GetUserOrdersDocumentFulfillment }
      : { query: GetUserOrdersDocument };
    refetchQueries.push(getUserOrdersQuery);
  }

  const [fireMutation, mutationResult] = useMutation<
    ICommitOrderMutationResponse,
    ICommitOrderMutationVariables
  >(CommitOrderDocument, {
    awaitRefetchQueries: true,
    refetchQueries,
  });
  const [fireMutationFulfillment, mutationResultFulfillment] = useMutation<
    ICommitOrderMutationResponse,
    ICommitOrderMutationVariables
  >(CommitOrderDocumentFulFillment, {
    awaitRefetchQueries: true,
    refetchQueries,
  });
  const isFulfillmentServiceEnabled = useFlag(
    LaunchDarklyFlag.ENABLE_FULFILLMENT_SERVICE_COMMIT_ORDER
  );

  const logOrderLatency = useCallback(
    (rbiOrder: IServerOrder) => {
      setMark(PerformanceMarks.COMMIT_END);

      logOrderLatencyDuration(ORDER_DURATION_ACTION, rbiOrder);
    },
    [logOrderLatencyDuration]
  );

  const onSuccess = useCallback(
    (rbiOrder: IServerOrder) => {
      onCompleted();
      onCommitSuccess(rbiOrder);
    },
    [onCommitSuccess, onCompleted]
  );

  const queryResult = useOrderStatus({
    failureStatuses: OrderSuccessFailureStatuses.failures,
    logOrderLatency,
    onSuccess,
    orderStatusPollInterval,
    rbiOrderId,
    successStatuses: OrderSuccessFailureStatuses.success,
    skip: skipPolling,
    handleCommitError: enableBackEndCommitOrder,
  });

  const { orderStatus, serverOrder, orderErrors } = queryResult;

  const enableDriveThruOrderTimedFire = useFlag(LaunchDarklyFlag.ENABLE_DRIVE_THRU_TIMED_FIRE);

  const commitOrder = useCallback(
    async (options: ICommitOrderInput) => {
      commitOptions.current = options;
      const delivery = buildCommitDeliveryInput(options.order, auth.user);

      // If using Apple or Google Pay, get the underlying credit card used and set this as the card type used
      options.creditType = mapCardType(options);
      let fireOrderIn = (options.order.fireOrderIn || 0) as FireOrderIn;

      const isDriveThrough = options.order.serviceMode === ServiceMode.DRIVE_THRU;
      // for backwards compatibility purposes, we need to convert 0 to 1 for ASAP drive thru orders
      if (isDriveThrough && enableDriveThruOrderTimedFire && options.order.fireOrderIn === 0) {
        fireOrderIn = 1;
      }
      if (isDriveThrough && !enableDriveThruOrderTimedFire && isPartnerOrder) {
        fireOrderIn = null;
      }

      const input = buildCommitOrderInput({
        ...options,
        fireOrderIn,
        rbiOrderId: rbiOrderId!,
        guestEmail: authGuest.guestUser()?.email,
      });

      const skipCoolingPeriod = !!options.skipCoolingPeriod;
      setMark(PerformanceMarks.COMMIT_START);
      const mutation = isFulfillmentServiceEnabled ? fireMutationFulfillment : fireMutation;
      const result = await mutation({
        variables: {
          delivery,
          input,
          skipCoolingPeriod,
        },
      });
      if (result.data) {
        setSkipPolling(false);
      }
      if (options.order.deliveryAddress?.shouldSave) {
        auth.refreshCurrentUser();
      }
      return result;
    },
    [
      auth,
      authGuest,
      enableDriveThruOrderTimedFire,
      fireMutation,
      fireMutationFulfillment,
      isFulfillmentServiceEnabled,
      rbiOrderId,
    ]
  );

  const forceCommitOrder = useCallback(() => {
    return commitOrder({ ...commitOptions.current, skipCoolingPeriod: true });
  }, [commitOrder]);

  return {
    commitOrder,
    commitResult: isFulfillmentServiceEnabled ? mutationResultFulfillment : mutationResult,
    commitErrorFromGetOrder: orderErrors,
    failure: queryResult.failure,
    forceCommitOrder,
    loading: mutationResult.loading || queryResult.loading || mutationResultFulfillment.loading,
    order: queryResult,
    orderStatus,
    serverOrder,
    success: queryResult.success,
    setSkipPolling,
    posErrors: queryResult.posErrors,
  };
};
