import React, { useMemo } from 'react';

import { ApolloProvider } from '@apollo/client';

import { IBaseProps } from '@rbi-ctg/frontend';
import { useCdpContext } from 'state/cdp';
import { useLocale } from 'state/intl';
import { useGatewayFlags } from 'state/launchdarkly';

import { getClient } from './client';

export const GraphQLProvider = ({ children }: IBaseProps) => {
  const { language, region } = useLocale();
  const gatewayFlags = useGatewayFlags();
  const { sessionId } = useCdpContext();

  const client = useMemo(
    () => getClient({ language, region, sessionId }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gatewayFlags]
  );

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export { useLazySanityQuery, useSanityQuery } from './hooks/use-sanity-query';
