import { useEffect } from 'react';

import { IUseAlertOrderCateringMinimum } from './types';

export const useAlertOrderCateringMinimum = ({
  calculateCartTotal,
  setCartCateringPriceTooLow,
  isCatering,
  checkoutCateringPriceMinimum = 0,
}: IUseAlertOrderCateringMinimum) => {
  useEffect(() => {
    if (!isCatering) {
      setCartCateringPriceTooLow(false);
      return;
    }

    setCartCateringPriceTooLow(calculateCartTotal() < checkoutCateringPriceMinimum);
  }, [calculateCartTotal, isCatering, checkoutCateringPriceMinimum, setCartCateringPriceTooLow]);
};
