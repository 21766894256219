import React from 'react';

import { useIntl } from 'react-intl';

import { BottomSheet } from 'components/bottom-sheet';
import { IconQrCodeScanner } from 'components/icons/qr-code-scanner';
import { IconRefillDrink } from 'components/icons/refill-drink';
import { QRCode } from 'components/qrcode';
import { useFreeRefillDrinks } from 'hooks/use-free-refill-drinks';
import { primitive } from 'styles/constants/primitives';
import { RBIBrand, brand } from 'utils/environment';

import { QRCodeTip, QRCodeWrapper } from './styles';

export const BottomSheetRefillDrinks = () => {
  const { formatMessage } = useIntl();
  const { isFreeRefillEnabled, isBottomSheetTimerValid, qrCodeInfoValue, refillDrinksTimers } =
    useFreeRefillDrinks();
  const isFHSBrand = brand() === RBIBrand.FHS;
  const brandColor = isFHSBrand ? primitive.fhs.$houseNavy : Styles.color.black;

  const minutes = refillDrinksTimers?.timeBottomSheet;

  const showBottomSheet = isFreeRefillEnabled && isBottomSheetTimerValid && !!qrCodeInfoValue;

  return (
    <BottomSheet
      showBottomSheet={showBottomSheet}
      previewIconPrefix={<IconRefillDrink />}
      previewIconSufix={<IconQrCodeScanner fill={brandColor} aria-hidden />}
      title={formatMessage({ id: 'refillYourDrink' })}
      description={formatMessage({ id: 'refillTapToScan' }, { minutes })}
      descriptionExpanded={formatMessage({ id: 'refillScanBelow' }, { minutes })}
    >
      <QRCodeWrapper>
        <QRCodeTip isFHSBrand={isFHSBrand}>
          {formatMessage({ id: 'refillQRCodeTipMachine' })}
        </QRCodeTip>
        <QRCode
          barcode={qrCodeInfoValue}
          options={{
            margin: 1,
            scale: 4,
            width: 178,
            color: {
              dark: brandColor,
            },
          }}
        />
      </QRCodeWrapper>
    </BottomSheet>
  );
};
