import * as React from 'react';
import { FC, useCallback } from 'react';

import { Card } from '@rbilabs/components-library/build/components/card';
import { useIntl } from 'react-intl';

import { ISanityImage } from '@rbi-ctg/menu';
import { Picture } from 'components/picture';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames, EventTypes } from 'state/cdp/constants';
import { useLocationContext } from 'state/location';
import { EventName, emitEvent } from 'utils/event-hub';

import { useTerms } from '../terms/terms.hook';

import { IMarketingCardProps } from './types';

/**
 *
 * MarketingCard is the Sanity Data component that wraps the Component-library Card component
 *
 */
export const MarketingCard: FC<IMarketingCardProps> = ({ card, onShowTermsModal, index }) => {
  const { navigate } = useLocationContext();
  const { logEvent, marketingTileClickEvent } = useCdpContext();
  const { formatMessage } = useIntl();

  // Colors
  const isCustom = card.hasCustomColors;
  const variant = isCustom ? 'campaign' : 'default';
  const buttonTextColor = card.buttonTextColor?.hex ?? undefined;
  const buttonBgColor = card.buttonBackgroundColor?.hex ?? undefined;
  const bgColor = card.backgroundColor?.hex ?? undefined;
  const textColor = card.textColor?.hex ?? undefined;

  // Content
  const header = card.header?.locale ?? '';
  const description = card.description?.locale ?? '';
  const sanityImage = card.image?.locale?.app as ISanityImage;
  const imageAlt = (card.image?.locale?.imageDescription ?? '') as string;
  const image = sanityImage && (
    <Picture
      image={sanityImage}
      alt={imageAlt}
      onImageLoaded={() => {
        emitEvent(EventName.MARKETING_IMAGE_LOADED);
      }}
    />
  );

  // Call to action
  const callToAction = (card.callToAction?.actionText?.locale ?? '') as string;
  const href = (card.callToAction?.actionUrl?.locale ?? '') as string;
  const logDataEvent = useCallback(() => {
    marketingTileClickEvent(header, index, card._id);
    const eventAttributes = {
      'Marketing Card ID': card._id,
      'Marketing Card URL': href,
    };
    // log the mParticle event
    logEvent?.(CustomEventNames.MARKETING_TILE_CLICK, EventTypes.Navigation, eventAttributes);
  }, [card._id, header, href, index, logEvent, marketingTileClickEvent]);
  const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    logDataEvent();
    navigate(href);
  };
  // Terms
  const hasTerms = card.hasTerms;
  // Fallback to "terms and conditions" localized
  const termsButtonText = (card.termsButton?.actionText?.locale ??
    formatMessage({ id: 'termsConditions' })) as string;
  const termsButtonUrl = (card.termsButton?.actionUrl?.locale ?? '') as string;
  const termsLinkHref = hasTerms ? termsButtonUrl : undefined;
  const termsLinkText = hasTerms ? termsButtonText : undefined;
  const termsText = card.termsText?.localeRaw;

  const onClickTermsLink = useTerms({
    onShowTermsModal,
    termsButtonText,
    termsButtonUrl,
    termsText,
  });

  return (
    <Card
      variant={variant}
      header={header}
      description={description}
      image={image}
      imageAlt={imageAlt}
      callToAction={callToAction}
      href={href}
      onClick={onClick}
      buttonTextColor={buttonTextColor}
      buttonBgColor={buttonBgColor}
      bgColor={bgColor}
      textColor={textColor}
      // Terms
      onClickTermsLink={onClickTermsLink}
      termsLinkHref={termsLinkHref}
      termsLinkText={termsLinkText}
    />
  );
};
