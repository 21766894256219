import React from 'react';

import { useIntl } from 'react-intl';

import { useMenuContext } from 'state/menu';
import { routes } from 'utils/routing';

import { ChangeServiceModeLinkContents } from './change-service-mode-link-contents';
import { ChangeServiceModeLinkProps } from './types';

export const getRouteForLink = (isOrderingEnabled: boolean) =>
  isOrderingEnabled ? routes.serviceMode : routes.storeLocator;

export const ChangeServiceModeLink: React.FC<ChangeServiceModeLinkProps> = ({
  storeSelected = true,
  orderingEnabled = true,
}) => {
  const { formatMessage } = useIntl();
  const { showStaticMenu } = useMenuContext();

  const noValidStoreSelected = !storeSelected && (!orderingEnabled || showStaticMenu);

  const linkText = noValidStoreSelected
    ? formatMessage({ id: 'selectRestaurant' })
    : formatMessage({ id: 'change' });
  const linkLabel = noValidStoreSelected ? linkText : formatMessage({ id: 'changeAddress' });

  const linkRoute = getRouteForLink(orderingEnabled);

  return (
    <ChangeServiceModeLinkContents
      linkLabel={linkLabel}
      linkRoute={linkRoute}
      linkText={linkText}
      orderingEnabled={orderingEnabled}
    />
  );
};
