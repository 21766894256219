import React from 'react';

import {
  StyledBody,
  StyledButton,
  StyledButtonsContainer,
  StyledDialog,
  StyledHeader,
  StyledOutlineButton,
  StyledPicture,
  StyledTextOnlyButton,
} from './actions-modal.styled';
import { IActionsModalProps, TextAlign } from './types';

export const ActionsModal = ({
  actions,
  bodyText,
  'data-testid': dataTestId,
  headerText,
  headerTextAlign = TextAlign.LEFT,
  image,
  imageAlt = '',
  modalAppearanceEventMessage,
}: IActionsModalProps) => {
  const Header = () => (
    <StyledHeader textAlign={headerTextAlign} data-testid="actions-modal-header">
      {headerText}
    </StyledHeader>
  );
  const Body = () => <StyledBody data-testid="actions-modal-body">{bodyText}</StyledBody>;
  const Actions = () => (
    <StyledButtonsContainer>
      {actions.map(
        ({
          dataTestId: actionDataTestId,
          actionLabel,
          actionHandler,
          textOnly = false,
          outline = false,
        }) => {
          let BtnComponent = StyledButton;
          if (textOnly) {
            BtnComponent = StyledTextOnlyButton;
          } else if (outline) {
            BtnComponent = StyledOutlineButton;
          }
          return (
            <BtnComponent key={actionLabel} data-testid={actionDataTestId} onClick={actionHandler}>
              {actionLabel}
            </BtnComponent>
          );
        }
      )}
    </StyledButtonsContainer>
  );

  return (
    <StyledDialog
      data-testid={dataTestId}
      headingComponent={<Header />}
      image={!!image && <StyledPicture image={image} alt={imageAlt} />}
      bodyComponent={<Body />}
      actions={<Actions />}
      modalAppearanceEventMessage={modalAppearanceEventMessage}
    />
  );
};
