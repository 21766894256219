import React, { FC, ReactNode, useEffect } from 'react';

import { useIntl } from 'react-intl';

import { useGoogleGeolocationLibrary } from 'hooks/geolocation/use-google-geolocation-library';
import { useEffectOnce } from 'hooks/use-effect-once';
import { useErrorModal } from 'hooks/use-error-modal';

export const GoogleGeolocationLibraryLoader: FC<{ children: ReactNode }> = ({ children }) => {
  const { formatMessage } = useIntl();
  const { demandLibrary, error, libraryLoaded } = useGoogleGeolocationLibrary();
  const [ErrorDialog, openErrorDialog] = useErrorModal({
    modalAppearanceEventMessage: 'Error: Fetching Restaurants Error',
  });

  useEffectOnce(() => {
    demandLibrary();
  });

  useEffect(() => {
    if (error) {
      openErrorDialog({
        message: formatMessage({ id: 'errorLoadingGeolocationLibrary' }),
      });
    }
  }, [error, formatMessage, openErrorDialog]);

  if (!libraryLoaded) {
    return null;
  }

  return (
    <>
      {children}
      <ErrorDialog />
    </>
  );
};
