import styled from 'styled-components';

import { ActionButton } from 'components/action-button';
import { BottomModal } from 'components/bottom-modal';
import { UnstyledButton } from 'components/unstyled-button';
import { primitive } from 'styles/constants/primitives';
import { fadeOut } from 'utils/style/animations';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-block-end: 2rem;
  margin-inline-start: 0;
  margin-inline-end: auto;
  > * {
    margin-block-end: 1rem;
  }

  ${Styles.desktop} {
    max-width: 36rem;
    margin-inline: auto;
  }
`;

export const StyledBottomModal = styled(BottomModal)`
  transform: translate(0, 100%);
  transition: transform 350ms ease-in-out;

  & > div {
    border-block-end: none;
  }
`;

export const CartList = styled.div`
  & > div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-content: flex-start;
    height: 70vh;
    border-block-end: 1px solid rgba(0, 0, 0, 0.1);
    gap: 12px;
    overflow: auto;
    padding-block-end: 1rem;
  }
`;

export const CartItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 8rem;
  border-radius: 0.75rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0.25rem 1rem;
  padding-block-start: 0.5rem;
  padding-block-end: 0.25rem;
  padding-inline-start: 0;
  padding-inline-end: 0.75rem;

  &.exit {
    animation: ${fadeOut} 0.5s forwards;
  }
`;

export const CartItemDetails = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  h2 {
    font-size: 0.875rem;
  }
`;

export const CartItemActions = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-block-start: auto;
  border-block-start: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0.55rem 0;
  margin-block-start: 0.45rem;

  & > div > div {
    gap: 1.5rem;

    & > button > svg {
      border: none;

      path:last-child {
        fill: ${Styles.color.primary} !important;
      }
    }
  }
`;

export const CartItemRemove = styled(UnstyledButton)`
  display: flex;
  align-items: center;
  margin-inline-end: auto;
  padding-inline-start: 1rem;
  gap: 0.4rem;
  font-family: ${Styles.fontFamily.brand};
  font-size: 0.875rem;

  svg {
    margin-block-start: -0.125rem;
  }
`;

export const ScanRedeemButton = styled(ActionButton)`
  margin: 0 1rem;
`;

export const InfoButton = styled(UnstyledButton)`
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;
  margin: 1rem;
`;

export const InfoBackButton = styled(UnstyledButton)`
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  margin: 1rem;
  background: ${primitive.$white};
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  height: 70vh;
  padding-inline: 1rem;
  padding-block-end: 1rem;

  ${Styles.mobileTiny} {
    flex-wrap: nowrap;
  }
`;

export const InfoTitle = styled.div`
  font-size: 1.125rem;
  margin-block-start: 1rem;
  margin-block-end: 0.5rem;
  font-family: ${Styles.fontFamily.brand};
`;

export const InfoDescription = styled.div`
  font-size: 0.75rem;
  margin-block-end: 1rem;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  gap: 1rem;
  padding-inline: 0.5rem;

  ${Styles.mobileTiny} {
    flex-wrap: wrap;
  }
`;

export const AddIncentiveButton = styled(ActionButton)`
  flex: 1;
  font-size: 0.85rem;
  padding-block: 0.5rem;
  padding-inline: 0.8rem;
  background: ${primitive.$white};

  ${Styles.mobileTiny} {
    flex: unset;
    width: 100%;
    margin-block-end: 0.3rem;
    justify-content: center;
  }
`;
