import { useLocationChange } from 'hooks/location/use-location-change';

export const useScrollTop = () => {
  /**
   * This scrolls top between page transitions. Browser and device change
   * which element holds native scroll. This should cover all the bases.
   */
  useLocationChange(() => {
    if (document.body.scrollTop) {
      document.body.scrollTop = 0;
    }
    if (document.documentElement) {
      document.documentElement.scrollTop = 0;
    }

    /**
     * necessary for mobile apps. Scrolling on body or document does nothing.
     * This is the explicit scroll target.
     * */
    const mainElement = document.getElementById('main');
    if (mainElement && mainElement.scrollTop) {
      // Old javascript contexts do not support `scrollTo`
      mainElement.scrollTo?.(0, 0);
    }
  });
};
