import React from 'react';

import { VisuallyHidden } from '@rbilabs/components-library/build/components/visually-hidden';
import { noop } from 'lodash';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { IServerOrder } from '@rbi-ctg/menu';
import { useOrderNumber } from 'hooks/use-order-number';
import { fullBrandName } from 'utils/environment';

import { MapContainer } from './common';
import { PickupCardMemo } from './pickup-card';

const StoreCardContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;

  ${Styles.desktop} {
    margin: 1rem auto;
    max-width: ${Styles.layout.smallerSectionMaxWidth};
  }
`;

const StoreCardContainerInner = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin-block-end: 2.5rem;
  padding-block-start: 2.5rem;
  padding-block-end: 0.5rem;
  padding-inline: 0;

  ${Styles.mobile} {
    margin-block-end: 7rem;
    margin-block-end: calc(8rem + env(safe-area-inset-bottom));
  }
  ${Styles.desktop} {
    margin-block-end: 1rem;
  }
`;

export function ConfirmPickup({ serverOrder }: { serverOrder: IServerOrder }) {
  const { formatMessage } = useIntl();
  const orderNumber = useOrderNumber(serverOrder);
  return (
    <MapContainer>
      <StoreCardContainer>
        <StoreCardContainerInner>
          <PickupCardMemo serverOrder={serverOrder} onOrderRefunded={noop} />
        </StoreCardContainerInner>
      </StoreCardContainer>
      <VisuallyHidden role="alert">
        {formatMessage(
          { id: 'pickUpOrderSuccessfulAlert' },
          {
            orderNumber,
            brand: fullBrandName(),
            address: `${serverOrder.cart.storeAddress.addressLine1} ${serverOrder.cart.storeAddress.addressLine2}`,
          }
        )}
      </VisuallyHidden>
    </MapContainer>
  );
}

export const ConfirmPickupMemo = React.memo(ConfirmPickup);
