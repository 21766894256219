import React from 'react';

import debounce from 'lodash/debounce';

import { TMediaQueryKey, mediaQueries } from 'styles/constants/media-queries';

// TODO ICFE-496 -  FIX the import after updating to @types/react >= 18.0.0
const useSyncExternalStore = (React as any).useSyncExternalStore;

export enum MediaQuery {
  Mobile = 'mobile',
  MobileTiny = 'mobileTiny',
  MobileSmall = 'mobileSmall',
  MobileLandscape = 'mobileLandscape',
  HeaderMobile = 'headerMobile',
  Desktop = 'desktop',
  DesktopLarge = 'desktopLarge',
  MobileFullscreen = 'mobileFullscreen',
}

const mediaQueryCleanerRegex = /(@media screen and |\{\})/g;

const externalStore = new Map<TMediaQueryKey, boolean>();

const updateStore = (mq: TMediaQueryKey, value: boolean) => {
  externalStore.set(mq, value);
};

const cleanMediaQueryString = (mediaQueryString: string) => {
  return mediaQueryString.replace(mediaQueryCleanerRegex, '');
};

const subscribeToMediaQuery = (mq: TMediaQueryKey, callback: VoidFunction) => {
  const query = cleanMediaQueryString(mediaQueries[mq]);
  const mediaQueryList = window.matchMedia(query);

  const handleChange = debounce(() => {
    updateStore(mq, mediaQueryList.matches);
    callback();
  }, 300);

  mediaQueryList.addEventListener('change', handleChange);

  return () => {
    mediaQueryList.removeEventListener('change', handleChange);
  };
};

export const useMediaQuery = (mq: TMediaQueryKey): boolean => {
  if (!externalStore.has(mq)) {
    const query = cleanMediaQueryString(mediaQueries[mq]);

    const initialMatch = window.matchMedia(query).matches;
    updateStore(mq, initialMatch);
  }

  const getSnapshot = () => externalStore.get(mq) ?? false;
  const subscribe = (callback: VoidFunction) => subscribeToMediaQuery(mq, callback);

  return useSyncExternalStore(subscribe, getSnapshot);
};
