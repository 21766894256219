import { DeliveryStatusQuery } from 'remote/queries/order';

import { QUERY_TYPE } from '../constants';
import { OrderError } from '../exceptions';

export const deliveryStatusQueryOptions = (rbiOrderId: string) => {
  return {
    queryType: QUERY_TYPE.LambdaGraphqlQuery,
    query: DeliveryStatusQuery,
    variables: { rbiOrderId },
    then: (data: any) => {
      if (data.errors || !data.order) {
        throw data.errors;
      }
      return data.order;
    },
    catch: (e: Error) => {
      throw new OrderError('An error occurred fetching the delivery status', e);
    },
  };
};
