import { useEffect } from 'react';

import { addMilliseconds, formatISO, parseISO } from 'date-fns';
import toNumber from 'lodash/toNumber';

import { ICartEntry } from '@rbi-ctg/menu';
import { useEffectOnUpdates } from 'hooks/use-effect-on-updates';
import { useEffectOnce } from 'hooks/use-effect-once';
import { useLocalStorageState } from 'hooks/use-local-storage-state';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import type { ICart } from 'state/loyalty/in-restaurant-redemption';
import { DIFFERENCE_TIME_UNITS, getDifferenceToNow } from 'utils/dateTime';
import { StorageKeys } from 'utils/local-storage';
import { logger } from 'utils/logger';

export type CartStorageKeys =
  | StorageKeys.ORDER_LAST_UPDATE
  | StorageKeys.IN_RESTAURANT_CART_LAST_UPDATE;

export interface IUseSetResetCartTimeoutParams {
  storageKey: CartStorageKeys;
  cart: ICartEntry[] | ICart;
  resetCartCallback: VoidFunction;
}

const HOUR_TO_MS = 60 * 60 * 1000;

export const parseCartLastUpdateDatetime = parseISO;
export const formatCartLastUpdateDatetime = formatISO;

/**
 * After the cart value change, the last update date time is stored in the localstorage
 * and a timeout is set to reset the cart.
 */
export const useSetResetCartTimeout = ({
  storageKey,
  cart,
  resetCartCallback,
}: IUseSetResetCartTimeoutParams) => {
  const resetCartTimeoutHrs = useFlag(LaunchDarklyFlag.RESET_CART_TIMEOUT_HRS);
  // store the last update in ISO format
  const [lastUpdate, setLastUpdate, clearLastUpdateKeyFromStorage] = useLocalStorageState<string>({
    key: storageKey,
    defaultReturnValue: '',
  });

  useEffectOnce(() => {
    // clear the cart if there is no timestamp and there is a LD variation
    if (!lastUpdate && resetCartTimeoutHrs && cart && cart.length > 0) {
      resetCartCallback();
      // TODO: remove after evaluating how often we're clearing cart with no timestamp
      logger.info({
        message: 'User cart was cleared because no timestamp was available',
      });
    }
  });

  useEffectOnUpdates(() => {
    // the cart contains at least 1 element
    if (cart && cart.length > 0) {
      setLastUpdate(formatCartLastUpdateDatetime(new Date()));
    }
  }, [JSON.stringify(cart), setLastUpdate]);

  useEffect(() => {
    if (!cart || cart.length === 0) {
      clearLastUpdateKeyFromStorage();
    }
  }, [cart, clearLastUpdateKeyFromStorage]);

  // this effect handles clearing the cart based on the LD variation
  useEffect(() => {
    if (!resetCartTimeoutHrs || !lastUpdate) {
      return;
    }

    const resetCartTimeoutMs = toNumber(resetCartTimeoutHrs) * HOUR_TO_MS;
    if (Number.isNaN(resetCartTimeoutMs) || resetCartTimeoutMs === 0) {
      return;
    }

    const parsedLastUpdate = parseCartLastUpdateDatetime(lastUpdate);
    // deadline is composed by the last update date time + timeout
    const deadlineTime = addMilliseconds(parsedLastUpdate, resetCartTimeoutMs);
    const remainingTimeMs = getDifferenceToNow(DIFFERENCE_TIME_UNITS.MILLISECONDS, deadlineTime);

    // execute the callback if it should have been run before
    if (remainingTimeMs <= 0) {
      resetCartCallback();
      return;
    }

    /**
     * Set timeout and clear it on hook's dependencies or component unmount.
     */
    const timeoutId = window.setTimeout(() => {
      resetCartCallback();
    }, remainingTimeMs);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [clearLastUpdateKeyFromStorage, lastUpdate, resetCartCallback, resetCartTimeoutHrs]);
};
