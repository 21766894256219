import {
  Button,
  OutlineButton,
  TextOnlyButton,
} from '@rbilabs/components-library/build/components/button';
import styled from 'styled-components';

import { DialogMemo } from 'components/dialog';
import { Picture } from 'components/picture';

type HeaderAlign = {
  textAlign?: string;
};

export const StyledHeader = styled.h1<HeaderAlign>`
  margin: 0 auto;
  font-family: ${Styles.fontFamily.alternative};
  font-size: 1.65rem;
  font-weight: ${Styles.fontWeight.heavy};
  text-align: ${props => (props.textAlign ? props.textAlign : 'left')};
  padding-block-end: 0.5rem;
`;

export const StyledBody = styled.p`
  margin-block-start: 0;
  margin-block-end: 1rem;
  margin-inline: auto;
  font-family: ${Styles.fontFamily.body};
  font-size: 1rem;
  font-weight: ${Styles.fontWeight.light};
  width: 98%;
`;

export const StyledDialog = styled(DialogMemo)`
  padding-block-start: 1.5rem;
  text-align: center;
`;

export const StyledPicture = styled(Picture)`
  max-width: 85%;
  margin: auto;

  img {
    object-fit: contain;
  }
`;

export const StyledButtonsContainer = styled.div``;

export const StyledButton = styled(Button)`
  width: 100%;
  margin-block-start: 1rem;
`;

export const StyledOutlineButton = styled(OutlineButton)`
  width: 100%;
  margin-block-start: 1rem;
`;

export const StyledTextOnlyButton = styled(TextOnlyButton)`
  width: 100%;
  margin: 1rem auto;
  text-transform: capitalize;
`;
