import { useCallback, useEffect, useRef } from 'react';

import { useNetworkContext } from 'state/network';
import { QueryConfig } from 'state/network/hook';

interface UsePollingOptions<R = any, V = any> {
  interval?: number;
  onError: (err: Error) => void;
  onResponse: (res: R) => void;
  queryOptions: QueryConfig<R, V>;
  retry?: number;
}

interface UsePollingHook {
  stopPolling: VoidFunction;
}

/**
 * usePolling() is a hook that starts polling our GraphQL API at a given
 * interval when mounted and stops polling when the component unmounts.
 *
 * TODO: beef this up so it can be used in the orders context when polling
 * for pricing data & successful order commit.
 */
export const usePolling = (options: UsePollingOptions): UsePollingHook => {
  const shouldPoll = useRef(false);
  const pollId = useRef<NodeJS.Timeout | null>(null);
  const { query } = useNetworkContext();

  const stopPolling = useCallback(() => {
    if (pollId.current) {
      clearTimeout(pollId.current);
      pollId.current = null;
    }
    shouldPoll.current = false;
  }, []);

  const poll = () => {
    const timeoutId = global.setTimeout(() => {
      query(options.queryOptions)
        .then((res: any) => {
          if (shouldPoll.current) {
            options.onResponse(res);
            poll();
          }
        })
        .catch((err: Error) => {
          if (options.retry && options.retry > 0) {
            options.retry--;
            poll();
          } else {
            stopPolling();
            options.onError(err);
            throw err;
          }
        });
    }, options.interval || 5000);

    pollId.current = timeoutId;
  };

  useEffect(() => {
    shouldPoll.current = true;
    poll();

    return () => {
      shouldPoll.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { stopPolling };
};
