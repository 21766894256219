import { EffectCallback, useRef } from 'react';

import { Nullable } from '@rbi-ctg/frontend';

import { useEffectOnUpdates } from './use-effect-on-updates';

export const useDebounceEffectOnUpdates = (
  debouncedEffect: EffectCallback,
  delay: number,
  inputs: unknown[]
) => {
  const timeoutRef = useRef<Nullable<number>>();

  useEffectOnUpdates(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }

    timeoutRef.current = window.setTimeout(debouncedEffect, delay);
  }, inputs); // eslint-disable-line react-hooks/exhaustive-deps
};
