import React from 'react';

import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useUIContext } from 'state/ui';

import { Price, PriceAndCaloriesText } from './price-and-calories.styled';
import { PriceAndCaloriesProps } from './types';

export const PriceAndCalories: React.FC<PriceAndCaloriesProps> = ({ price, calories }) => {
  const { formatCurrencyForLocale } = useUIContext();
  const hideUiCalories = useFlag(LaunchDarklyFlag.HIDE_UI_CALORIES) || false;

  if (!price && (!calories || hideUiCalories)) {
    return null;
  }

  const formattedPrice = price && `+${formatCurrencyForLocale(price)}`;

  return (
    <PriceAndCaloriesText>
      {!hideUiCalories && calories}
      {!!formattedPrice && <Price>{formattedPrice}</Price>}
    </PriceAndCaloriesText>
  );
};
