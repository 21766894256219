import { CognitoUserSession } from 'amazon-cognito-identity-js';

import { LocalStorage, StorageKeys } from 'utils/local-storage';

import { getCurrentCognitoUser } from './util';

export { getCurrentSession } from './get-current-session';
export { getCurrentGuestSession } from './get-current-guest-session';

// This method should get us information about a user being signed-in
// without validating the session with the cognito server. If this does
// not do that we should just move to keep track of a user being signed-in
// or not via localstorage
export const userHasSignedIn = (): Promise<boolean> => {
  const cognitoUser = getCurrentCognitoUser();

  return new Promise(resolve => {
    if (cognitoUser) {
      cognitoUser.getSession((err: Error | null, session: CognitoUserSession) => {
        resolve(!err && session.isValid());
      });
    } else {
      resolve(false);
    }
  });
};

// Return if there is an unexpected sign outs tracking
export const checkForUnexpectedSignOut = (): Promise<Error | null> => {
  return new Promise(resolve => {
    const cognitoUser = getCurrentCognitoUser();

    if (cognitoUser) {
      cognitoUser.getSession((err: Error | null, session: CognitoUserSession) => {
        if (!err && session.isValid()) {
          // User is considered signed in; no unexpected sign-out
          LocalStorage.setItem(StorageKeys.USER_SIGNED_IN_SUCCESSFULLY, true);
          resolve(null);
        } else {
          // Check for the unexpected sign-out flag
          const userSignedInSuccessfully = LocalStorage.getItem(
            StorageKeys.USER_SIGNED_IN_SUCCESSFULLY
          );
          if (userSignedInSuccessfully) {
            LocalStorage.removeItem(StorageKeys.USER_SIGNED_IN_SUCCESSFULLY);
            resolve(new Error('Unexpected sign-out detected.'));
          } else {
            resolve(null);
          }
        }
      });
    } else {
      resolve(null);
    }
  });
};
