import React from 'react';

import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { CookieBannerType } from 'components/layout/cookie-banner-container/types';
import { getConfigValue } from 'utils/environment';

const CookieSettingsLink = styled.span`
  font-family: ${Styles.fontFamily.base};
  font-weight: ${Styles.fontWeight.normal};
  margin: 0;
  text-align: start;
  text-transform: none;
  text-decoration: none;
  line-height: 1.6;
  cursor: pointer;
`;

const CookieSettingsContainer = styled.div`
  margin-block-start: 20px;
`;

export const CookieSettings = () => {
  const { formatMessage } = useIntl();
  const { cookieBanner }: { cookieBanner: string } = getConfigValue('cookieBanners');

  const toggleBanner = () => {
    window?.OneTrust?.ToggleInfoDisplay();
  };

  if (cookieBanner !== CookieBannerType.ONE_TRUST_COOKIE_BANNER) {
    return null;
  }

  return (
    <CookieSettingsContainer>
      <CookieSettingsLink onClick={toggleBanner} aria-hidden="true">
        {formatMessage({ id: 'cookiesSettings', defaultMessage: 'Cookies Settings' })}
      </CookieSettingsLink>
    </CookieSettingsContainer>
  );
};
