import { LocalStorage, StorageKeys } from 'utils/local-storage';

export function getLastAcceptedCookieTimestamp(): number | null {
  return LocalStorage.getItem(StorageKeys.LAST_TIME_COOKIES_ACCEPTED);
}

export function getLastIgnoredCookieTimestamp(): number | null {
  return LocalStorage.getItem(StorageKeys.LAST_TIME_COOKIES_IGNORED);
}

export function getLastCookieVersion(): number | null {
  return LocalStorage.getItem(StorageKeys.LAST_COOKIE_VERSION);
}
