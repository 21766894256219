import React, { FC } from 'react';

import { ILoyaltyQrAndShortCodeModalContentFragment } from 'generated/sanity-graphql';
import { ActionsModal } from 'state/loyalty/in-restaurant-redemption/modals/actions-modal';

import { ModalBody } from './body';

export interface IHowToEarnModalProps {
  howToEarnModal: ILoyaltyQrAndShortCodeModalContentFragment | null;
  onDismiss: VoidFunction;
}

export const HowToEarnModal: FC<IHowToEarnModalProps> = ({ howToEarnModal, onDismiss }) => {
  if (!howToEarnModal) {
    return null;
  }

  const modalProps = {
    actions: [
      {
        dataTestId: 'how-to-earn-close-action',
        actionLabel: howToEarnModal.buttonCtaLabel?.locale ?? '',
        actionHandler: onDismiss,
        textOnly: true,
      },
    ],
    bodyText: <ModalBody data={howToEarnModal} />,
    headerText: howToEarnModal.title?.locale ?? '',
    modalAppearanceEventMessage: 'Qr and Short code How to Earn Modal',
  };

  return <ActionsModal data-testid="how-to-earn-dialog" {...modalProps} />;
};
