import React, { useCallback, useEffect } from 'react';

import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Modal, ModalContent, ModalHeading } from 'components/modal';
import { ChallengeContainer, ChallengeForm } from 'components/three-ds-challenge-modal/styled';
import { usePaymentContext } from 'state/payment';
import { TLocalizationKey } from 'types/i18n';

interface IThreeDsChallengeModalProps {
  acsUrl: string;
  challengeRequestToken: string;
  onChallengeSuccess: (creq: string) => void;
  headerIdMessage: TLocalizationKey;
  onThreeDSAbort?: VoidFunction;
}

export const ThreeDsChallengeModal: React.FC<IThreeDsChallengeModalProps> = ({
  acsUrl,
  challengeRequestToken,
  onChallengeSuccess,
  headerIdMessage,
  onThreeDSAbort,
}) => {
  const navigate = useNavigate();
  const { cleanThreeDSFlow } = usePaymentContext();
  const { formatMessage } = useIntl();

  const handleRef = useCallback((node: HTMLFormElement | null) => {
    node?.submit();
  }, []);

  const handleMessage = useCallback(
    (event: MessageEvent) => {
      const origin = new URL(event.origin);
      if (!origin.hostname.endsWith('.rbictg.com')) {
        return;
      }

      if (event.data.type === '3ds-response') {
        const paramChallengeResponse = 'cres';
        const searchParams = new URLSearchParams(event.data.body);
        const challengeResponse = searchParams.get(paramChallengeResponse);

        if (challengeResponse) {
          onChallengeSuccess(challengeResponse);
        }

        const typeResponse = searchParams.get('type');
        if (typeResponse === 'Failed') {
          onChallengeSuccess('');
        }
      }
    },
    [onChallengeSuccess]
  );

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [handleMessage, onChallengeSuccess]);

  const dismissFlow = () => {
    cleanThreeDSFlow();
    if (onThreeDSAbort) {
      onThreeDSAbort();
    } else {
      navigate('../');
    }
  };

  const modalHeading = formatMessage({ id: headerIdMessage });

  return (
    <Modal
      allowsDismiss={!onThreeDSAbort}
      onDismiss={dismissFlow}
      eventData={{
        modalAppearanceEventMessage: 'Add Card Challenge',
      }}
    >
      <ModalContent expand={true}>
        <ModalHeading>{modalHeading}</ModalHeading>
        <ChallengeForm action={acsUrl} method="POST" target="3DSIframe" ref={handleRef}>
          <input type="hidden" name="creq" value={challengeRequestToken} />
          <input type="submit" />
        </ChallengeForm>
        <ChallengeContainer name="3DSIframe" title="3DS challenge" frameBorder={0} />
      </ModalContent>
    </Modal>
  );
};
