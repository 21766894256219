import * as React from 'react';
import { FC } from 'react';

import { Box } from '@rbilabs/components-library/build/components/layout';

import { ActionButton } from 'components/action-button';

import {
  Body,
  Header,
  ItemText,
  StyledModal,
  StyledPicture,
} from './modal-item-unavailable.styled';
import { theme } from './theme';
import { IModalItemUnavailableViewProps } from './types';

export const ModalItemUnavailableView: FC<IModalItemUnavailableViewProps> = ({
  handleContinue,
  handleChangeStore,
  unavailableItemName,
  header,
  image,
  selectNewBtnText,
  continueBtnText,
  body,
  imageAlt,
  hideSelectNewBtn,
}) => (
  <StyledModal
    eventData={{
      modalAppearanceEventMessage: 'Static menu item unavailable',
    }}
    data-testid="item-unavailable-static-menu"
    allowsDismiss={false}
  >
    <Header>{header}</Header>
    <Body>{body}</Body>
    <StyledPicture alt={imageAlt} image={image} />
    <ItemText>{unavailableItemName}</ItemText>
    <Box flexDirection="column" center margin={`${theme.buttonContainerMarginTop} 0 0`}>
      <Box width="100%" center margin="0 0 1rem">
        <ActionButton fullWidth onClick={handleContinue}>
          {continueBtnText}
        </ActionButton>
      </Box>
      {!hideSelectNewBtn && (
        <Box width="100%" center margin="0">
          <ActionButton
            fullWidth
            variant={theme.changeStoresButtonVariant}
            onClick={handleChangeStore}
          >
            {selectNewBtnText}
          </ActionButton>
        </Box>
      )}
    </Box>
  </StyledModal>
);
