import React, { useCallback, useEffect, useRef } from 'react';

import { Icon } from '@rbilabs/components-library/build/components/icon';
import { useIntl } from 'react-intl';

import { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';
import { SHORT_CODE_TEST_ID } from 'components/offer-redemption-modal/constants/index';
import { QRCode } from 'components/qrcode/qr-code';
import { useDialogModal } from 'hooks/use-dialog-modal';
import { ShortCodeError } from 'pages/loyalty/loyalty-widgets/loyalty-qr-and-short-code-widget/short-code-error';
import { ShortCodeLoading } from 'pages/loyalty/loyalty-widgets/loyalty-qr-and-short-code-widget/short-code-loading';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames, EventTypes } from 'state/cdp/constants';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import {
  ShortCodeState,
  useInRestaurantRedemptionContext,
} from 'state/loyalty/in-restaurant-redemption';
import { addStringInBetween } from 'utils/add-string-in-between';
import { isMobile } from 'utils/environment';
import { PerformancePages, performanceReport, trackComponent } from 'utils/render-performance';

import { RewardRedemptionListMemo } from '../../loyalty-in-restaurant-redemption/reward-redemption-list';

import { QR_CODE_SELECTOR, QR_CODE_TEST_ID, barcodeOptions } from './constants';
import {
  Code,
  Container,
  Content,
  Footer,
  GenerateButton,
  IconContainer,
  QrCodeHeader,
  ShortCodeText,
  Title,
} from './loyalty-qr-and-short-code-widget.styled';
import { HowToEarnModal, IHowToEarnModalProps } from './modal/how-to-earn-modal';
import { ILoyaltyQRAndShortCodeWidgetProps } from './types';

const QR_CODE_LOADING_TIMEOUT = 1000 * 10; //10 seconds

export const LoyaltyQRAndShortCodeWidget: React.FC<ILoyaltyQRAndShortCodeWidgetProps> = ({
  title,
  shortCodeText,
  qrAndShortCodeHowToEarnModalContent,
  qrAndShortCodeErrorModalContent,
  shortCodeAlwaysDisplayGenerateButton,
}) => {
  const { formatMessage } = useIntl();
  const { trackEvent } = useCdpContext();

  const enableRedesignLoyaltyQRCodePage = useFlag(
    LaunchDarklyFlag.ENABLE_REDESIGN_LOYALTY_QR_CODE_PAGE
  );

  const isRedesignEnabled = isMobile() && enableRedesignLoyaltyQRCodePage;

  const {
    shortCodeState,
    shortCodeLoading,
    shortCode,
    generateShortCode,
    resetShortCode,
    setRequestError: setQRCodeRequestError,
  } = useInRestaurantRedemptionContext();

  const [HowToEarnDialog, openHowToEarnDialog] = useDialogModal<{}, IHowToEarnModalProps>({
    Component: HowToEarnModal,
  });

  // Track performance for certain elements on the page
  trackComponent(PerformancePages.MY_CODE_PAGE, 'QR Code', QR_CODE_SELECTOR, () => {
    trackEvent({
      name: CustomEventNames.LOYALTY_LOAD,
      type: EventTypes.Other,
      attributes: performanceReport(PerformancePages.MY_CODE_PAGE),
    });
  });

  const timeoutId = useRef<number>();

  // We want to check if the QR Code is taking too long to load
  const startQrCodeLoadingTimeout = useCallback(() => {
    clearTimeout(timeoutId.current);
    // @ts-ignore
    timeoutId.current = setTimeout(() => {
      if (
        (!shortCodeLoading && !shortCode && shortCodeState === ShortCodeState.RequestError) ||
        (shortCodeLoading &&
          (shortCodeState === ShortCodeState.Expired || shortCodeState === ShortCodeState.None))
      ) {
        resetShortCode();
        setQRCodeRequestError();
      }
    }, QR_CODE_LOADING_TIMEOUT);
  }, [shortCode, shortCodeLoading, shortCodeState]);

  useEffect(() => {
    startQrCodeLoadingTimeout();

    // When we have the qr code (status Pending) already
    if (shortCodeState === ShortCodeState.Pending) {
      clearTimeout(timeoutId.current);
    }
  }, [shortCodeState]);

  if (shortCodeLoading) {
    return (
      <>
        <ShortCodeLoading shortCodeExpirationTitle={formatMessage({ id: 'loading' })} />
        {!isRedesignEnabled ? <RewardRedemptionListMemo loyaltyEmptyCartTileWidget={null} /> : null}
      </>
    );
  }

  if (shortCodeState === ShortCodeState.RequestError) {
    return (
      <>
        <ShortCodeError
          shortCodeExpirationTitle={qrAndShortCodeErrorModalContent?.title?.locale}
          shortCodeExpirationButtonCtaLabel={
            qrAndShortCodeErrorModalContent?.buttonCtaLabel?.locale
          }
          onRefresh={generateShortCode}
        />
        <RewardRedemptionListMemo loyaltyEmptyCartTileWidget={null} />
      </>
    );
  }

  return (
    <>
      <Container data-testid="loyalty-qr-and-short-code-widget">
        <Content>
          {!isRedesignEnabled ? (
            <IconContainer data-testid="how-to-earn" onClick={openHowToEarnDialog}>
              <Icon icon={'info'} color={'tertiary'} width="24px" aria-hidden />
            </IconContainer>
          ) : null}
          <QrCodeHeader>
            <Title>{title.locale}</Title>
          </QrCodeHeader>
          {shortCode && (
            <QRCode data-testid={QR_CODE_TEST_ID} barcode={shortCode} options={barcodeOptions} />
          )}
        </Content>
        <Footer>
          <ShortCodeText>{shortCodeText.locale}</ShortCodeText>
          <Code data-testid={SHORT_CODE_TEST_ID}>
            {addStringInBetween(shortCode || '', 2, ' ')}
          </Code>
          {shortCodeAlwaysDisplayGenerateButton && (
            <GenerateButton
              variant={ActionButtonVariants.OUTLINE}
              data-testid="loyalty-qr-and-short-code-generate-new-code"
              size={ActionButtonSizes.LARGE}
              perceptible
              onClick={generateShortCode}
              isLoading={shortCodeLoading}
              startIcon={<Icon icon={'refresh'} color={'primary'} width="20px" aria-hidden />}
            >
              {formatMessage({ id: 'generateNewCode' })}
            </GenerateButton>
          )}
        </Footer>
      </Container>

      {!isRedesignEnabled ? <RewardRedemptionListMemo loyaltyEmptyCartTileWidget={null} /> : null}
      {/* Modals */}
      <HowToEarnDialog howToEarnModal={qrAndShortCodeHowToEarnModalContent} />
    </>
  );
};
