export const storeMarkerFavClosed = `
<svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" fill="none" viewBox="0 0 28 29">
    <g filter="url(#filter0_d)">
        <path fill="#F5EBDC" fill-rule="evenodd" d="M14 24.382c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z" clip-rule="evenodd"/>
    </g>
    <path fill="#BBA391" fill-rule="evenodd" d="M13.87 21.607l-5.838-6.013c-1.073-1.128-1.336-2.802-.663-4.206.573-1.22 1.797-2 3.144-2.006.732 0 1.445.236 2.031.675l.957.7.368.275.37-.275.962-.7c.584-.438 1.294-.675 2.025-.675 1.34.01 2.556.791 3.125 2.006.683 1.396.435 3.07-.625 4.206l-5.857 6.013z" clip-rule="evenodd"/>
    <defs>
        <filter id="filter0_d" width="28" height="28" x="0" y=".382" color-interpolation-filters="sRGB" filterUnits="userSpaceOnUse">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="2"/>
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        </filter>
    </defs>
</svg>
`;
