import React from 'react';

import { IBaseItem } from '@rbi-ctg/menu';
import { useCartItem } from 'pages/cart/cart-item/use-cart-item';
import { CustomEventNames } from 'state/cdp/constants';

import {
  CartItemPrice,
  UpsellCartItemCol,
  UpsellCartItemName,
  UpsellCartItemPicture,
  UpsellCartItemRow,
  UpsellCartItemWrapper,
} from './styles';

interface UpsellModalItemProps {
  item: IBaseItem;
  isAdded?: boolean;
  actions?: React.ReactNode;
  chipComponent?: React.ReactNode;
  index?: number;
  flow?: 'menu' | 'cart';
}

export const UpsellItem: React.FC<UpsellModalItemProps> = ({
  item,
  flow,
  isAdded,
  actions,
  index,
  chipComponent,
}) => {
  const { price, itemCalories } = useCartItem({
    item,
    logEventData: { logEventName: CustomEventNames.UPSELL_DISPLAYED },
    hasUniqueEvent: !!index, // only first event will be sent
  });

  return (
    <UpsellCartItemWrapper $isAdded={isAdded} $flow={flow}>
      {chipComponent}

      <UpsellCartItemPicture image={item.image} alt={item.name?.locale} $flow={flow} />

      <UpsellCartItemCol>
        <UpsellCartItemName title={item.name?.locale}>{item.name?.locale}</UpsellCartItemName>

        <UpsellCartItemRow>
          <CartItemPrice>
            <span data-testid={`upsell-cart-price-text-${item._id}`}>{price}</span>
          </CartItemPrice>
          <span>{itemCalories}</span>
        </UpsellCartItemRow>
      </UpsellCartItemCol>

      {actions}
    </UpsellCartItemWrapper>
  );
};
