import { useEffect, useRef } from 'react';

import delv from 'dlv';
import { noop } from 'lodash';

import { useLocationContext } from 'state/location';
import { ILocation } from 'state/location/types';

export function useLocationChange(handleLocationChange = noop) {
  const location = useLocationContext();
  const prevLocationRef = useRef<ILocation | undefined>();

  useEffect(() => {
    const prevPathName = delv(prevLocationRef, 'current.location.pathname', '');
    const locationPathName = delv(location, 'location.pathname', '');

    if (prevPathName !== locationPathName) {
      handleLocationChange(prevLocationRef.current, location);
      prevLocationRef.current = location;
    }
  }, [handleLocationChange, location]);

  return location;
}
