import React from 'react';

import { useIntl } from 'react-intl';

import { IconBkPayLogo } from 'components/icons/bk-pay-logo';
import { useBluecodeContext } from 'state/bluecode';

import { BKPayLogoButton } from './styled';

export const BkPayButton = () => {
  const { formatMessage } = useIntl();

  const { isBluecodeInitialized, openBluecodeView, userHasBluecodeAccount } = useBluecodeContext();

  const title = formatMessage({ id: 'bk_logo_title' });
  const handleButtonClick = () => openBluecodeView();

  if (isBluecodeInitialized && userHasBluecodeAccount) {
    return (
      <BKPayLogoButton onClick={handleButtonClick}>
        <IconBkPayLogo title={title} data-testid="bkpay-logo" />
      </BKPayLogoButton>
    );
  }
  return null;
};
