import * as React from 'react';
import { FC } from 'react';

import { primitive } from 'styles/constants/primitives';

import { Lock, LockOverlay } from './reward-lock.styled';

export const RewardLock: FC = props => (
  <>
    <LockOverlay {...props}></LockOverlay>
    <Lock fill={primitive.$white} />
  </>
);
