import React from 'react';

import styled from 'styled-components';

import { Animation } from 'components/lottie-animations/favorite-loading';

const IconWrapper = styled.div`
  height: 2rem;
  width: 2rem;
`;

export const LoadingAnimation = () => (
  <IconWrapper>
    <Animation />
  </IconWrapper>
);
