import React, { FC, useCallback } from 'react';

import { useIntl } from 'react-intl';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { ActionButtonVariants } from 'components/action-button';
import { IconTrash } from 'components/icons/trash';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames, EventTypes } from 'state/cdp/constants';
import { useLocationContext } from 'state/location';
import {
  ICartEntryDetailsReward,
  ICartEntryType,
  useInRestaurantRedemptionContext,
} from 'state/loyalty/in-restaurant-redemption';
import {
  ICartEntryOffer,
  ICartEntryReward,
} from 'state/loyalty/in-restaurant-redemption/hooks/use-in-restaurant-redemption-cart/types';
import { isSystemwideOffer } from 'state/loyalty/in-restaurant-redemption/utils';
import { isDiscountReward } from 'state/loyalty/types';
import { primitive } from 'styles/constants/primitives';
import { HapticsNotificationType, hapticNotification } from 'utils/haptic';

import { OfferRedemptionIncrementor } from '../../loyalty-in-restaurant-redemption/reward-redemption-list/badge-and-incrementor/offer-redemption-incrementor';
import { RewardRedemptionIncrementor } from '../../loyalty-in-restaurant-redemption/reward-redemption-list/badge-and-incrementor/reward-redemption-incrementor';
import { componentMapping } from '../../loyalty-in-restaurant-redemption/reward-redemption-list/reward-redemption-item';
import { CustomScrollElement } from '../custom-scroll-element';
import {
  CartItem,
  CartItemActions,
  CartItemDetails,
  CartItemRemove,
  CartList,
  Container,
  ScanRedeemButton,
} from '../loyalty-cart-drawer.styled';

import { InRestaurantScreen } from './types';

interface CartListDrawerProps {
  setDrawerType: (type: InRestaurantScreen) => void;
}

export const CartListDrawer: FC<CartListDrawerProps> = ({ setDrawerType }) => {
  const { formatMessage } = useIntl();
  const {
    inRestaurantRedemptionCart,
    updateInRestaurantRedemptionEntryQuantity,
    removeInRestaurantRedemptionEntry,
  } = useInRestaurantRedemptionContext();
  const { trackEvent } = useCdpContext();
  const { location } = useLocationContext();

  const handleUpdateEntry = useCallback(
    (cartEntry: ICartEntryReward | ICartEntryOffer, quantity: number) => {
      updateInRestaurantRedemptionEntryQuantity(cartEntry, quantity);
      hapticNotification({ type: HapticsNotificationType.SUCCESS });
    },
    [updateInRestaurantRedemptionEntryQuantity]
  );

  const onCartRemove = useCallback(
    (entry: ICartEntryReward | ICartEntryOffer) => {
      trackEvent({
        name: CustomEventNames.BUTTON_CLICK,
        type: EventTypes.Other,
        attributes: {
          Name: 'Remove',
          Path: location.pathname,
        },
      });
      removeInRestaurantRedemptionEntry(entry);
    },
    [location.pathname, removeInRestaurantRedemptionEntry, trackEvent]
  );
  return (
    <Container>
      <CartList>
        <CustomScrollElement>
          <TransitionGroup component={null}>
            {inRestaurantRedemptionCart.map(cartEntry => {
              const { details, type } = cartEntry;
              const components = componentMapping[type];
              const { ItemPicture, ItemName } = components;
              return (
                <CSSTransition key={cartEntry.referenceId} timeout={{ exit: 500 }}>
                  <CartItem>
                    <CartItemDetails>
                      <ItemPicture entryCartDetails={details} objectFitContain />
                      <ItemName entryCartDetails={details} />
                    </CartItemDetails>
                    <CartItemActions>
                      <CartItemRemove onClick={() => onCartRemove(cartEntry)}>
                        <IconTrash fill={primitive.bk.$bbqBrown} height="16" />
                        {formatMessage({ id: 'remove' })}
                      </CartItemRemove>

                      {cartEntry.type === ICartEntryType.REWARD &&
                        !isDiscountReward(
                          (cartEntry.details as ICartEntryDetailsReward).reward
                        ) && (
                          <RewardRedemptionIncrementor
                            quantity={cartEntry.quantity}
                            details={cartEntry.details}
                            onChange={(value: number) => handleUpdateEntry(cartEntry, value)}
                          />
                        )}
                      {isSystemwideOffer(cartEntry) && (
                        <OfferRedemptionIncrementor
                          quantity={cartEntry.quantity}
                          onChange={(value: number) => handleUpdateEntry(cartEntry, value)}
                        />
                      )}
                    </CartItemActions>
                  </CartItem>
                </CSSTransition>
              );
            })}
          </TransitionGroup>
        </CustomScrollElement>
      </CartList>
      <ScanRedeemButton
        onClick={() => {
          setDrawerType(InRestaurantScreen.QR_CODE);
        }}
        aria-label={formatMessage({ id: 'yourInRestaurantCartScanRedeem' })}
        data-testid="your-in-restaurant-cart-scan-redeem-button"
        variant={ActionButtonVariants.BOX_SHADOW_PRIMARY}
        eventAttributes={{
          Name: CustomEventNames.SCAN_AND_REDEEM,
        }}
      >
        {formatMessage({ id: 'yourInRestaurantCartScanRedeem' })}
      </ScanRedeemButton>
    </Container>
  );
};
