import React, { useEffect, useState } from 'react';

import { ActionButtonVariants } from 'components/action-button';
import {
  FulfillmentPickupType,
  RbiOrderStatus,
  useGetUserOrdersQuery,
} from 'generated/rbi-graphql';
import { useFeatureBeeper } from 'hooks/use-feature-beeper';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { ServiceMode, useOrderContext } from 'state/order';

import {
  ButtonContainer,
  Description,
  DismissButton,
  ModalContent,
  ModalHead,
  ModalInner,
  ModalPicture,
  StyledModal,
} from './styled';

const ORDER_PROGRESS_POLLING_DURATION_IN_MS = 10000;
const ALLOWED_SERVICE_MODES = [ServiceMode.EAT_IN, ServiceMode.TAKEOUT];

export const ModalBeeper: React.FC = () => {
  const { featureBeeperLoading, featureBeeper } = useFeatureBeeper();
  const [showBeeperModal, setShowBeeperModal] = useState(false);
  const [previouslyOrderReadyToPickup, setpreviouslyOrderReadyToPickup] = useState<
    boolean | undefined
  >(undefined);
  const { isAuthenticated } = useAuthContext();
  const { serverOrder } = useOrderContext();

  const isAuth = isAuthenticated();
  // TODO: fulfillment service backend is missing the `fulfillmentDetails` field on order type
  // update this when backend is updated
  const { data, startPolling, loading, stopPolling } = useGetUserOrdersQuery({
    variables: {
      limit: 1,
      orderStatuses: [RbiOrderStatus.INSERT_SUCCESSFUL, RbiOrderStatus.UPDATE_SUCCESSFUL],
    },
    skip: !isAuth || featureBeeperLoading,
  });

  const recentOrder = data?.userOrders?.orders?.[0];
  const isRecentOrderReadyToPickup =
    recentOrder?.fulfillmentDetails?.pickupType === FulfillmentPickupType.BEEPER &&
    recentOrder?.fulfillmentDetails?.pickupReady;
  const isTriggeredReadyToPickup =
    previouslyOrderReadyToPickup !== undefined &&
    !previouslyOrderReadyToPickup &&
    isRecentOrderReadyToPickup;

  useEffect(() => {
    startPolling(ORDER_PROGRESS_POLLING_DURATION_IN_MS);
  }, [serverOrder, startPolling]);

  useEffect(() => {
    // Proces only if :
    //  - data are not in loading
    //  - we have any previous order with BEEPER type (if type present)
    //  - the state of readyToPickup was changed since previous response
    if (
      !loading &&
      recentOrder &&
      (!recentOrder.fulfillmentDetails ||
        recentOrder.fulfillmentDetails.pickupType === FulfillmentPickupType.BEEPER) &&
      previouslyOrderReadyToPickup !== isRecentOrderReadyToPickup
    ) {
      // Show Beeper Modal if detected change of readyToPickup between requests in polling
      if (isTriggeredReadyToPickup) {
        setShowBeeperModal(true);
      }

      // State to storage state of current order for next request
      // It will be possible to remove this state when Apollo library will be updated
      // then we can use previousData (https://www.apollographql.com/docs/react/api/react/hooks/#previousdata)
      // It's added in 3.3.0 version of Apollo Client
      // https://github.com/apollographql/apollo-client/blob/main/CHANGELOG.md#apollo-client-330
      setpreviouslyOrderReadyToPickup(isRecentOrderReadyToPickup ?? undefined);
    }
  }, [
    loading,
    isTriggeredReadyToPickup,
    isRecentOrderReadyToPickup,
    stopPolling,
    previouslyOrderReadyToPickup,
    recentOrder,
    data,
  ]);

  useEffect(() => {
    const isAllowedServiceMode =
      recentOrder &&
      recentOrder.cart.serviceMode &&
      ALLOWED_SERVICE_MODES.includes(recentOrder?.cart.serviceMode);
    // Stop polling if:
    //  - user don't have any orders yet
    //  - the order is readyToPickup
    //  - service mode of the previous order is not one of EAT_IN TAKEOUT
    if (
      !loading &&
      (data?.userOrders?.count === 0 || isRecentOrderReadyToPickup || !isAllowedServiceMode)
    ) {
      stopPolling();
    }
  }, [data, isRecentOrderReadyToPickup, loading, recentOrder, stopPolling]);

  if (featureBeeperLoading || !showBeeperModal) {
    return null;
  }

  const onModalDismiss = () => {
    setShowBeeperModal(false);
    setpreviouslyOrderReadyToPickup(undefined);
  };

  return (
    <StyledModal
      onDismiss={onModalDismiss}
      eventData={{
        modalAppearanceEventMessage: 'Beeper Modal',
      }}
    >
      <ModalInner>
        <ModalHead>
          <ModalPicture image={featureBeeper?.beeperModalImage?.locale} alt="" objectFitContain />
          <h2>{featureBeeper?.beeperModalTitle?.locale}</h2>
        </ModalHead>
        <ModalContent>
          <Description>{featureBeeper?.beeperModalBody?.locale}</Description>
          <ButtonContainer>
            <DismissButton
              variant={ActionButtonVariants.PRIMARY}
              aria-label={featureBeeper?.beeperButtonText?.locale || ''}
              data-testid="beeper-dismiss-button"
              perceptible
              onClick={onModalDismiss}
            >
              {featureBeeper?.beeperButtonText?.locale}
            </DismissButton>
          </ButtonContainer>
        </ModalContent>
      </ModalInner>
    </StyledModal>
  );
};

export const ModalBeeperWrapper: React.FC = () => {
  const enableBeeperModal = useFlag(LaunchDarklyFlag.ENABLE_BEEPER_MODAL);
  return enableBeeperModal ? <ModalBeeper /> : null;
};
