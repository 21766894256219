import { noop } from 'lodash';
import store from 'store';

import { logger } from 'utils/logger';

import { Keys, LOCAL_STORAGE_VERSION } from './constants';

export class WebLocalStorage {
  // increment this value any time the shape of
  // data stored in localStorage changes
  static readonly currentVersion = LOCAL_STORAGE_VERSION;

  static getAll = (): Promise<{ [key: string]: any }> => {
    return Promise.resolve(
      Object.values(Keys).reduce((acc, key) => ({ ...acc, [key]: store.get(key) }), {})
    );
  };

  static getItem = <V = any>(key: Keys): V | null => {
    return store.get(key) || null;
  };

  static setItem = <V = any>(key: Keys, value: V) => {
    try {
      store.set(key, value);
    } catch (error) {
      const message = 'Local Storage setItem Error';
      logger.warn({ error, message });
    }
  };

  static isCurrentVersion = () =>
    Number(WebLocalStorage.getStoredVersion()) === LOCAL_STORAGE_VERSION;

  static clear = ({ excludeKeys = [] }: { excludeKeys?: Keys[] } = {}) => {
    const allExcludeKeys = [...excludeKeys, Keys.STORAGE_VERSION];

    Object.values(Keys).forEach(storageKey => {
      if (!allExcludeKeys.includes(storageKey)) {
        store.remove(storageKey);
      }
    });
  };

  static clearAll = () => {
    store.clearAll();
  };

  static removeItem = (storageKey: string) => {
    store.remove(storageKey);
  };

  static setCurrentVersion = () => store.set(Keys.STORAGE_VERSION, LOCAL_STORAGE_VERSION);

  private static getStoredVersion = () => store.get(Keys.STORAGE_VERSION);

  static async sync() {
    return new Promise(resolve => {
      resolve(noop());
    });
  }
}
