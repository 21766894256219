import React, { ReactNode, useCallback, useRef, useState } from 'react';

import { isApolloError } from '@apollo/client';
import { Box } from '@rbilabs/components-library/build/components/layout';
import delve from 'dlv';
import { noop } from 'lodash';
import { useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';

import { Nullable } from '@rbi-ctg/frontend';
import { ActionButton } from 'components/action-button';
import { IConfirmProps } from 'components/confirm-dialog';
import { DialogMemo, IDialogProps } from 'components/dialog';
import { DialogCb, IUseDialogComponentProps, IUseDialogProps } from 'hooks/use-dialog-modal';
import { useMediaQuery } from 'hooks/use-media-query';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames, EventTypes } from 'state/cdp/constants';
import { TLocalizationKey } from 'types/i18n';
import {
  IGraphqlErrorMap,
  IRbiError,
  createGqlErrorMap,
  fetchRbiErrorDetails,
  isRbiError,
  parseGraphQLErrorCodes,
} from 'utils/errors';
import { logger } from 'utils/logger';

import { useRoutes } from './use-routes';

export interface IRecordRbiErrorEvents {
  rbiErrors: IRbiError[];
  attrs?: { [key: string]: string | number | boolean };
}

export type ModalCb = (data: IErrorModal) => void;
export type RecordRbiErrorType = ({ rbiErrors, attrs }: IRecordRbiErrorEvents) => void;

interface IUseErrorModalArgs<T> {
  Component?: React.ComponentType<IUseDialogComponentProps>;
  onConfirm?: DialogCb<T>;
  onCancel?: DialogCb<T>;
  onDismiss?: DialogCb<T>;
  onOpen?: ModalCb;
  type?: string;
  showCancel?: boolean;
  init?: boolean;
  allowDismiss?: boolean;
  modalAppearanceEventMessage?: string;
}

export interface IErrorModal {
  body?: string | ReactNode;
  link?: {
    key: TLocalizationKey;
    to: string;
    text: string;
  };
  message?: string | ReactNode;
  error?: Error;
  name?: string;
  title?: string;
  modalAppearanceEventMessage?: string;
  confirmationText?: string;
  dismissText?: string;
  onConfirm?: Function;
  onDismiss?: Function;
  rbiError?: IRbiError[];
}

type Props<P> = IUseDialogProps & Partial<IDialogProps> & Partial<IConfirmProps> & P;
export type UseErrorDialogHook<P = any> = [
  React.FC<Props<P>>,
  ModalCb,
  VoidFunction,
  RecordRbiErrorType,
];

export const useErrorModal = <T extends object, P = {}>({
  Component,
  onConfirm = noop,
  onCancel = noop,
  onDismiss = onCancel,
  onOpen = noop,
  init = false,
  allowDismiss = true,
  /**
   * A small string sent to mParticle describing the purpose of the modal.
   */
  modalAppearanceEventMessage,
}: IUseErrorModalArgs<T> = {}): UseErrorDialogHook<P> => {
  const { formatMessage } = useIntl();
  const [open, setOpen] = useState(init);
  const { getLocalizedRouteForPath } = useRoutes();
  const [pendingData, setPending] = useState<Nullable<T>>(null);
  const modalAppearanceEventMessageOverride = useRef<string | null>(null);
  const errorEventMessageOverride = useRef<string | null>(null);
  const isDesktop = useMediaQuery('desktop');
  const { trackEvent } = useCdpContext();
  const { pathname } = useLocation();

  /**
   * Records RBI error events by tracking each error and logging the overall process.
   *
   * @param {IRbiError[]} rbiErrors - An array of RBI error objects to be recorded.
   * @param {Object} [attrs] - Optional attributes to include with each event.
   * @returns {void}
   */
  const recordRbiErrorEvents = useCallback(
    ({ rbiErrors, attrs }: IRecordRbiErrorEvents) => {
      try {
        rbiErrors.forEach(error => {
          trackEvent({
            name: CustomEventNames.ERROR,
            type: EventTypes.Other,
            attributes: {
              'Error Code': error?.errorCode,
              Name: error.message,
              Path: pathname,
              ...attrs,
            },
          });
        });

        logger.error({
          error: rbiErrors,
          message: 'Record Rbi Error Modal',
        });
      } catch (error) {
        logger.error({
          message: 'Error while recording error events',
          error,
        });
      }
    },
    [pathname, trackEvent]
  );

  /**
   * Displays an error modal based on the provided error data.
   *
   * @param {IErrorModal} data - The error data containing error details and message.
   * @param {Error | IGraphqlErrorMap | undefined} data.error - The error object to be logged.
   * @param {string | null} data.message - The message to be displayed in the modal.
   * @param {string} [data.modalAppearanceEventMessage] - Optional override message for modal appearance.
   *
   * @returns {void}
   */
  const parseErrorModal = useCallback(
    (data: IErrorModal) => {
      if (data) {
        const { error, modalAppearanceEventMessage: eventMessageOverride } = data;
        const message: string | null = typeof data.message === 'string' ? data.message : null;
        let parsedError: Error | IGraphqlErrorMap | undefined = error;

        if (eventMessageOverride) {
          modalAppearanceEventMessageOverride.current = eventMessageOverride;
        }

        if (parsedError && isApolloError(parsedError)) {
          const errors = parseGraphQLErrorCodes(parsedError);

          if (errors.length) {
            const [{ errorCode, message: errorMessage }] = errors;
            errorEventMessageOverride.current = [errorCode, errorMessage].join(' ');
            // we cannot filter datadog results based on array elements
            // so we create a map of the list of errors supplied by apollo
            parsedError = createGqlErrorMap([...parsedError.graphQLErrors]);
          }
        }

        logger.error({
          error: parsedError,
          message: eventMessageOverride || modalAppearanceEventMessage,
          modalHeader: eventMessageOverride || modalAppearanceEventMessage,
          modalMessage: message,
        });
      }
    },
    [modalAppearanceEventMessage]
  );

  /**
   * Opens an error dialog with the provided error data.
   *
   * @param {IErrorModal} data - The error data containing details for the modal.
   * @returns {void} - This method does not return a value.
   */
  const openErrorDialog = useCallback(
    (data: IErrorModal) => {
      onOpen(data);

      const { rbiError, ...rest } = data;

      if (rbiError?.length && isRbiError(rbiError?.[0])) {
        const rbiErrorDetails = fetchRbiErrorDetails({ rbiError: rbiError?.[0], formatMessage });

        recordRbiErrorEvents({
          rbiErrors: rbiError,
          attrs: {
            'Response Title': rbiErrorDetails.title,
            'Response Description': rbiErrorDetails.body,
          },
        });

        const handlerActions = {
          ...data,
          dismissText: rbiErrorDetails.cta,
          ...rbiErrorDetails,
        } as T;

        setPending(handlerActions);
      } else {
        setPending(rest as T);
        parseErrorModal(rest);
      }
      setOpen(true);
    },
    [formatMessage, onOpen, parseErrorModal, recordRbiErrorEvents]
  );

  const dismissDialog = useCallback(() => {
    onDismiss(pendingData);
    setPending(null);
    if (allowDismiss) {
      setOpen(false);
    }
  }, [allowDismiss, onDismiss, pendingData]);

  const confirmDialog = useCallback(() => {
    onConfirm(pendingData);
    setPending(null);
    setOpen(false);
    onCancel();
  }, [onCancel, onConfirm, pendingData]);

  const ErrorDialogComponent: UseErrorDialogHook<P>[0] = useCallback(
    ({
      buttonLabel = formatMessage({ id: 'okay' }),
      heading = formatMessage({ id: 'somethingWrong' }),
      image,
      ...rest
    }) => {
      if (!open) {
        return null;
      }

      const msg = delve(pendingData as T, 'message', null);
      const title = delve(pendingData as T, 'title', null);
      const body = delve(pendingData as T, 'body', null);
      const link = delve(pendingData as T, 'link', null);

      const confirmationText = delve(pendingData as T, 'confirmationText', null);
      const dismissText = delve(pendingData as T, 'dismissText', null);
      const onConfirmAction = delve(pendingData as T, 'onConfirm', null);
      const onDismissAction = delve(pendingData as T, 'onDismiss', null);

      const [firstPendingRbiError] = delve(pendingData as T, 'rbiError', null) || [];

      const modalAppearanceEvent = isRbiError(firstPendingRbiError?.error)
        ? undefined
        : modalAppearanceEventMessageOverride.current || modalAppearanceEventMessage;

      const errorEventMessage = isRbiError(firstPendingRbiError?.error)
        ? undefined
        : errorEventMessageOverride.current;

      let linkBody;
      if (Component) {
        return <Component onDismiss={dismissDialog} onConfirm={confirmDialog} />;
      }

      if (link) {
        linkBody = formatMessage(
          { id: link.key },
          {
            link: (
              <Link to={getLocalizedRouteForPath(link.to)} onClick={dismissDialog}>
                {link.text}
              </Link>
            ),
          }
        );
      }

      const dialogBody = linkBody || body || msg || formatMessage({ id: 'errorProcessingRequest' });

      const actions =
        confirmationText && dismissText && onConfirmAction && onDismissAction ? (
          <>
            <Box width="100%" minWidth="auto">
              <ActionButton
                onlyIcon
                fullWidth
                onClick={onDismissAction}
                data-testid="error-dialog-dismiss-btn"
              >
                {dismissText}
              </ActionButton>
            </Box>
            <Box margin={isDesktop ? '0 0 0 1rem' : '1rem 0 0'} width="100%" minWidth="auto">
              <ActionButton
                onlyIcon
                fullWidth
                onClick={onConfirmAction}
                data-testid="error-dialog-confirm-btn"
              >
                {confirmationText}
              </ActionButton>
            </Box>
          </>
        ) : (
          <Box margin="1rem" width="100%" minWidth="auto">
            <ActionButton
              onlyIcon
              fullWidth
              onClick={onDismissAction || confirmDialog}
              data-testid="error-dialog-confirm-btn"
            >
              {dismissText || buttonLabel}
            </ActionButton>
          </Box>
        );

      return (
        <DialogMemo
          heading={title || heading}
          body={dialogBody}
          image={image}
          onDismiss={dismissDialog}
          actions={actions}
          modalAppearanceEventMessage={modalAppearanceEvent}
          errorEventMessage={errorEventMessage}
          aria-label="error"
          {...rest}
        />
      );
    },
    [
      formatMessage,
      open,
      pendingData,
      Component,
      confirmDialog,
      dismissDialog,
      modalAppearanceEventMessage,
      getLocalizedRouteForPath,
      isDesktop,
    ]
  );

  return [ErrorDialogComponent, openErrorDialog, dismissDialog, recordRbiErrorEvents];
};
