import { useCallback, useState } from 'react';

import { geolocationLibraryIsLoaded, loadGeolocationLibrary } from 'utils/geolocation';
import { logger } from 'utils/logger';

export const useGoogleGeolocationLibrary = () => {
  const [libraryLoaded, setLibraryLoaded] = useState(geolocationLibraryIsLoaded());
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const demandLibrary = useCallback(async () => {
    if (!libraryLoaded) {
      try {
        setLoading(true);
        await loadGeolocationLibrary();
      } catch (e) {
        setError(true);
        logger.warn(e);
      } finally {
        setLibraryLoaded(geolocationLibraryIsLoaded());
        setLoading(false);
      }
    }
  }, [libraryLoaded]);

  return {
    demandLibrary,
    error,
    libraryLoaded,
    loading,
  };
};
