import React, { createContext, useContext } from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';

import { BranchJourneysBannerCssOverride } from './branch-journeys-banner-css-override';
import { IBranchContext, useBranch } from './hooks/use-branch';

export const BranchContext = createContext<IBranchContext>({} as IBranchContext);

export const useBranchContext = () => useContext(BranchContext);

export const BranchProvider = ({ children }: IBaseProps) => {
  const ctxValue = useBranch();

  return (
    <BranchContext.Provider value={ctxValue}>
      <BranchJourneysBannerCssOverride />
      {children}
    </BranchContext.Provider>
  );
};
