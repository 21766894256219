import React, { SVGProps } from 'react';

export const IconLoyaltyLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg data-dir="ltr" width="102" height="27" viewBox="0 0 102 27" fill="none" {...props}>
    <path
      d="M10.7742 13.4265C10.7742 9.33105 12.9783 7 15.8574 7C18.7543 7 20.9417 9.3299 20.9417 13.4265C20.9417 17.5231 18.7532 19.853 15.8574 19.853C12.9783 19.8519 10.7742 17.522 10.7742 13.4265ZM16.4378 13.4265C16.4378 10.6555 16.2762 10.2835 15.8584 10.2835C15.4407 10.2835 15.2791 10.6544 15.2791 13.4265C15.2791 16.1802 15.4407 16.5695 15.8584 16.5695C16.2762 16.5695 16.4378 16.1802 16.4378 13.4265Z"
      fill="#FF8732"
    />
    <path
      d="M23.3926 18.722C23.3926 18.4756 23.4566 18.2809 23.5206 18.0863C23.698 17.5922 23.8271 17.0267 23.8271 16.1618V15.9153C23.8271 15.3856 23.762 15.0504 23.5049 14.5206C22.9098 13.2664 22.2663 12.0145 21.3973 10.3722C20.8987 9.41858 20.5125 9.17211 20.5125 8.34173C20.5125 7.86493 20.8179 7.24761 21.6229 7.24761H25.6135C26.2401 7.24761 26.5624 7.76012 26.5624 8.27148C26.5624 9.1007 26.1761 9.24236 26.1761 9.82513C26.1761 10.1775 26.2569 10.3906 26.4018 10.7258C26.5141 10.9907 26.6107 11.1669 26.7712 11.1669C26.9161 11.1669 26.9801 11.0436 27.1407 10.7258C27.3013 10.3906 27.3821 10.1257 27.3821 9.82513C27.3821 9.24236 27.1082 8.87152 27.1082 8.30603C27.1082 7.61731 27.5427 7.24646 28.0896 7.24646H30.3903C30.9214 7.24646 31.3076 7.65186 31.3076 8.27033C31.3076 9.065 30.8731 9.29419 30.2937 10.3538C29.8749 11.1127 29.4414 12.0306 29.0394 12.8426C28.4758 13.9897 28.0423 14.8016 28.0423 15.9142V16.1607C28.0423 17.0256 28.1872 17.5911 28.3478 18.0852C28.4118 18.2798 28.4758 18.4733 28.4758 18.7209C28.4758 19.2334 28.1704 19.6042 27.6718 19.6042H24.1966C23.698 19.6054 23.3926 19.2346 23.3926 18.722Z"
      fill="#FF8732"
    />
    <path
      d="M29.2084 18.5458C29.2084 18.3166 29.2567 18.069 29.3522 17.8214C30.2212 15.4742 31.38 11.4122 32.12 8.80011C32.4579 7.60003 33.0048 7.24646 33.7773 7.24646H35.1281C35.9332 7.24646 36.5441 7.61731 36.882 8.74713C37.7186 11.5723 39.2468 16.3564 39.8587 17.8744C39.9395 18.0863 39.9878 18.2982 39.9878 18.4929C39.9878 19.1102 39.6015 19.6043 39.0379 19.6043H35.2572C34.6464 19.6043 34.3892 19.1631 34.3892 18.6863C34.3892 18.5101 34.4375 18.3155 34.5015 18.1566C34.6138 17.8917 34.6789 17.6452 34.6789 17.4506C34.6789 17.1857 34.5666 16.991 34.2937 16.9738C34.0198 16.9553 33.9715 16.9553 33.6986 16.9738C33.4246 16.991 33.2798 17.2029 33.2798 17.5208C33.2798 17.7155 33.3281 17.9631 33.4404 18.228C33.4887 18.3685 33.5212 18.5286 33.5212 18.6863C33.5212 19.1286 33.2966 19.6043 32.7497 19.6043H30.2076C29.5296 19.6054 29.2084 19.1286 29.2084 18.5458ZM34.3084 13.8503C34.4364 13.8503 34.5173 13.7974 34.5015 13.6557C34.4218 13.1086 34.2612 12.4015 34.2118 12.208C34.1793 12.0675 34.1153 11.9961 34.0187 11.9961C33.9064 11.9961 33.8571 12.0663 33.8256 12.208C33.7773 12.4199 33.6482 13.1086 33.5359 13.7098C33.5191 13.7974 33.5842 13.8503 33.6639 13.8503H34.3084Z"
      fill="#FF8732"
    />
    <path
      d="M40.4244 18.6876C40.4244 18.4227 40.4895 18.1405 40.5693 17.8226C40.7949 17.0107 40.8275 15.7749 40.8275 13.4266C40.8275 11.0783 40.7792 9.78949 40.5693 9.03052C40.4895 8.71265 40.4244 8.41205 40.4244 8.16559C40.4244 7.6001 40.6826 7.24768 41.326 7.24768H44.4791C45.1875 7.24768 45.557 7.63581 45.557 8.21857C45.557 8.6424 45.429 8.90729 45.2516 9.24243C45.0742 9.57758 44.9136 10.0555 44.8821 10.6901C44.8496 11.4134 44.8338 12.1378 44.8338 12.8611C44.8338 13.673 44.8506 14.485 44.8821 15.2969C44.9146 15.9154 45.1718 16.2506 45.7186 16.2506C46.8123 16.2506 46.6517 14.6612 47.8913 14.6612C48.8402 14.6612 49.1299 15.3499 49.0018 16.1262C48.8895 16.7262 48.8244 17.3447 48.7929 18.0691C48.7447 19.093 48.3101 19.6043 47.3938 19.6043H41.4079C40.7624 19.6055 40.4244 19.2519 40.4244 18.6876Z"
      fill="#FF8732"
    />
    <path
      d="M51.8788 18.6876C51.8788 18.4227 51.9439 18.1405 52.0236 17.8226C52.2493 17.0107 52.2818 15.7749 52.2818 13.4266C52.2818 11.0783 52.2336 9.78949 52.0236 9.03052C51.9439 8.71265 51.8788 8.41205 51.8788 8.16559C51.8788 7.6001 52.137 7.24768 52.7804 7.24768H57.0439C59.3614 7.24768 61.0177 8.46618 61.0177 11.2372C61.0177 14.0093 59.3614 15.0332 56.9631 15.0332C56.5779 15.0332 56.3365 15.1749 56.3365 15.7922C56.3365 16.6928 56.4645 17.1696 56.6576 17.6107C56.8025 17.9459 56.9158 18.2292 56.9158 18.6346C56.9158 19.2173 56.6419 19.6055 55.9187 19.6055H52.7814C52.1359 19.6055 51.8788 19.2519 51.8788 18.6876ZM57.5582 11.2717C57.5582 10.4771 57.1079 10.1949 56.7059 10.1949C56.432 10.1949 56.3365 10.3008 56.3365 10.6003V11.9593C56.3365 12.2599 56.4645 12.3647 56.7059 12.3647C57.1079 12.367 57.5582 12.0664 57.5582 11.2717Z"
      fill="#FF8732"
    />
    <path
      d="M61.2905 18.6876C61.2905 18.4227 61.3546 18.1405 61.4343 17.8226C61.66 17.0107 61.6925 15.7749 61.6925 13.4266C61.6925 11.0782 61.6442 9.78949 61.4343 9.03052C61.3546 8.71265 61.2905 8.41205 61.2905 8.16559C61.2905 7.6001 61.5477 7.24768 62.1911 7.24768H67.6942C68.7564 7.24768 69.2067 7.67151 69.2067 8.46618C69.2067 8.83703 69.2707 9.38409 69.3673 9.75494C69.5762 10.5669 69.1259 11.2902 68.3219 11.2902C67.3562 11.2902 67.1474 10.4068 66.3748 10.4068C66.0537 10.4068 65.764 10.636 65.764 11.1485C65.764 11.6253 66.0694 11.8718 66.4725 11.8718C67.0036 11.8718 66.9878 11.6426 67.5347 11.6426C68.2264 11.6426 68.5969 12.2611 68.5969 13.2665C68.5969 14.2731 68.2264 15.0321 67.5347 15.0321C66.9878 15.0321 67.0036 14.6439 66.4725 14.6439C66.1019 14.6439 65.764 14.8904 65.764 15.3499C65.764 15.9154 66.1177 16.1791 66.6331 16.1791C67.9199 16.1791 67.7593 15.0666 68.8698 15.0666C69.7221 15.0666 70.06 15.7553 69.8994 16.6203C69.8186 17.0798 69.7703 17.6096 69.7703 18.0679C69.7703 19.0918 69.1595 19.6032 68.049 19.6032H62.1921C61.5477 19.6055 61.2905 19.2519 61.2905 18.6876Z"
      fill="#FF8732"
    />
    <path
      d="M70.4861 18.6876C70.4861 18.4227 70.5512 18.1405 70.6309 17.8226C70.8566 17.0107 70.8891 15.7749 70.8891 13.4266C70.8891 11.0783 70.8409 9.78949 70.6309 9.03052C70.5512 8.71265 70.4861 8.41205 70.4861 8.16559C70.4861 7.6001 70.7443 7.24768 71.3877 7.24768H76.0217C78.3393 7.24768 80.1246 8.36023 80.1246 10.8848C80.1246 12.42 79.4487 13.2147 78.7402 13.5855C78.6279 13.6385 78.5628 13.7087 78.5628 13.7801C78.5628 13.8677 78.6111 13.9379 78.7402 14.0093C79.7059 14.5564 80.0753 15.5803 80.4133 16.869C80.6222 17.681 80.8321 17.9102 80.8321 18.4756C80.8321 19.1287 80.5099 19.6055 79.625 19.6055H77.2277C76.4069 19.6055 76.0049 19.2001 76.0049 18.387C76.0049 17.8215 76.0375 17.2214 76.0375 16.0398C76.0375 15.4213 75.8118 15.1392 75.4738 15.1392C75.1044 15.1392 74.9427 15.4398 74.9427 15.8624C74.9427 16.4279 75.0708 17.0994 75.2324 17.575C75.3447 17.9102 75.5221 18.281 75.5221 18.6346C75.5221 19.2173 75.2482 19.6055 74.525 19.6055H71.3877C70.7443 19.6055 70.4861 19.2519 70.4861 18.6876ZM76.1666 11.2717C76.1666 10.4771 75.7163 10.1949 75.3143 10.1949C75.0403 10.1949 74.9448 10.3008 74.9448 10.6003V11.9593C74.9448 12.2599 75.0729 12.3647 75.3143 12.3647C75.7163 12.367 76.1666 12.0664 76.1666 11.2717Z"
      fill="#FF8732"
    />
    <path
      d="M81.2614 18.6876C81.2614 18.4227 81.3254 18.1405 81.4051 17.8226C81.6308 17.0107 81.6633 15.7749 81.6633 13.4266C81.6633 11.0783 81.6151 9.78949 81.4051 9.03052C81.3254 8.71265 81.2614 8.41205 81.2614 8.16559C81.2614 7.6001 81.5185 7.24768 82.1619 7.24768H85.3149C85.9426 7.24768 86.248 7.65308 86.248 8.18286C86.248 8.46618 86.184 8.67695 86.0706 9.03052C85.8775 9.66626 85.8292 10.2836 85.8292 11.767C85.8292 11.9432 85.9268 12.0503 86.0381 12.0503C86.1189 12.0503 86.2155 11.9973 86.3121 11.8914C86.8914 11.2026 87.0846 10.902 87.4708 10.249C87.5831 10.0728 87.6472 9.82635 87.6472 9.57873C87.6472 8.92571 87.2619 8.80248 87.2619 8.16674C87.2619 7.68994 87.5359 7.24883 88.1793 7.24883H91.3491C91.896 7.24883 92.1531 7.68994 92.1531 8.18402C92.1531 8.48461 92.0565 8.81976 91.5905 9.31384C90.9461 9.98413 90.2869 10.8675 89.6278 11.7508C89.5312 11.8741 89.4829 12.033 89.4829 12.1919C89.4829 12.2806 89.4997 12.4039 89.5469 12.5098C90.2386 14.2408 91.7837 17.2594 92.363 18.0184C92.5236 18.2476 92.6212 18.5309 92.6212 18.7958C92.6212 19.2185 92.3315 19.6078 91.7196 19.6078H87.793C87.2777 19.6078 87.0048 19.025 86.9723 18.2833C86.9397 17.3654 86.6983 16.2713 86.3614 15.2992C86.2974 15.123 86.2008 15.0528 86.1032 15.0528C85.9594 15.0528 85.846 15.176 85.846 15.4064C85.846 15.9534 85.8943 16.7654 86.0706 17.4714C86.1683 17.8768 86.248 18.2303 86.248 18.6714C86.248 19.2012 85.9426 19.6066 85.3307 19.6066H82.1609C81.5175 19.6055 81.2614 19.2519 81.2614 18.6876Z"
      fill="#FF8732"
    />
    <path
      d="M94.2523 19.4637C92.659 18.8637 93.335 17.6279 93.0621 16.0743C93.0453 15.9856 93.0453 15.9153 93.0453 15.8451C93.0453 15.1748 93.5606 14.6968 94.1232 14.6968C94.4444 14.6968 94.7992 14.8558 95.0721 15.2623C95.6189 16.0754 96.2308 16.3922 96.8092 16.3922C97.1314 16.3922 97.3403 16.2516 97.3403 15.951C97.3403 15.6159 97.1629 15.4213 95.9401 14.9445C94.6207 14.432 93.124 13.4254 93.124 10.8306C93.1261 8.25305 95.0091 7 97.4064 7C99.1928 7 100.592 7.17621 100.737 8.30603C100.818 8.95905 100.882 9.34717 101.091 10.1603C101.139 10.3365 101.155 10.4781 101.155 10.6371C101.155 11.3431 100.672 11.7312 100.108 11.7312C99.7386 11.7312 99.3366 11.5723 99.0144 11.2014C98.3384 10.4424 98.0173 10.2835 97.6793 10.2835C97.3739 10.2835 97.1965 10.4424 97.1965 10.6716C97.1965 10.9722 97.4053 11.1657 98.4518 11.5366C100.656 12.3312 101.637 13.3724 101.637 15.685C101.637 18.1393 100.125 19.8507 97.0359 19.8507C95.7648 19.8519 95.0574 19.7643 94.2523 19.4637Z"
      fill="#FF8732"
    />
    <path
      d="M5.98589 19.3071L5.64792 16.2171C5.55135 15.3867 5.42225 14.8926 4.98772 14.8926C4.64974 14.8926 4.48915 15.1932 4.48915 15.6171C4.48915 16.535 4.61826 17.029 4.7631 17.3653C4.90795 17.7005 5.06854 17.9654 5.06854 18.3892C5.06854 18.972 4.79459 19.3601 4.31177 19.3601H0.901609C0.258202 19.3601 0 19.0065 0 18.4422C0 18.1773 0.0640258 17.8951 0.144845 17.5773C0.37051 16.7653 0.401998 15.5295 0.401998 13.18C0.401998 10.8317 0.353716 9.54296 0.144845 8.78284C0.0640258 8.46612 0 8.16552 0 7.91791C0 7.35357 0.257153 7 0.901609 7H5.56815C7.88567 7 9.6721 8.11255 9.6721 10.6371C9.6721 12.0502 9.04443 13.0211 7.6768 13.4277C7.59598 13.4634 7.53196 13.5336 7.53196 13.6039C7.53196 13.6569 7.58024 13.7271 7.6768 13.7628C8.89959 14.2569 9.46323 14.751 10.0101 15.8819C10.8781 17.78 10.6062 20.6327 11.0859 23.1515C11.2874 24.2088 11.9015 24.9447 12.4168 24.9447C12.7065 24.9447 12.9154 24.7858 12.9154 24.5036C12.9154 23.9738 12.0463 23.8149 12.0463 22.4559C12.0463 21.2374 12.8996 20.2838 14.1864 20.2838C15.2812 20.2838 16.3749 21.2017 16.3749 23.0029C16.3749 25.5804 14.4761 26.9763 12.1103 26.9763C8.94052 26.9752 6.42042 23.2978 5.98589 19.3071ZM5.68045 11.0264C5.68045 10.2317 5.23018 9.94952 4.82713 9.94952C4.55318 9.94952 4.45662 10.0555 4.45662 10.3561V11.7162C4.45662 12.0168 4.58572 12.1228 4.82713 12.1228C5.23018 12.1205 5.68045 11.821 5.68045 11.0264Z"
      fill="#FF8732"
    />
  </svg>
);
