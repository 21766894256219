import React, { useMemo } from 'react';

import styled from 'styled-components';

import { Price } from '../price';

import { IPriceAndQuantityProps } from './types';

const Quantity = styled.div`
  text-align: end;
`;

const MWContainer = styled.div`
  min-width: 25%;
  display: flex;
  flex-direction: column;
  & span {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-self: flex-end;
  }
`;

export const PriceAndQuantity: React.FC<IPriceAndQuantityProps> = ({
  item,
  isOffer,
  offerId,
  showQuantity,
  rewardApplied,
}) => {
  const valueId = useMemo(() => `item-quantity-${item.name.replace(/\s+/g, '-')}`, [item.name]);

  return (
    <MWContainer id={`item-price-${item.cartId || item._id}`}>
      <Price item={item} isOffer={isOffer} offerId={offerId} rewardApplied={rewardApplied} />
      {showQuantity && <Quantity id={valueId}>{item.quantity}</Quantity>}
    </MWContainer>
  );
};
