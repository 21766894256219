import React, { useContext, useMemo, useState } from 'react';

import { noop } from 'lodash';
import { Meta } from 'react-meta-elements';
import { useLocation, useNavigate } from 'react-router-dom';

import { appUrl, createRedirectHandler, getPathMatcher } from 'utils/routing';

import { useDisallowedUrls } from './hooks/use-disallowed-urls';
import { useEnhancedNavigate } from './hooks/use-enhanced-navigate';
import { useNavigateBack } from './hooks/use-navigate-back';
import { ILocation, ILocationProviderProps } from './types';

export { Link, useMatch } from 'react-router-dom';

export const LocationContext = React.createContext<ILocation>({} as ILocation);
export const useLocationContext = () => useContext(LocationContext);

export function LocationProvider({ children, basename }: ILocationProviderProps) {
  const [storeLocatorCallbackUrl, setStoreLocatorCallbackUrl] = useState('');

  const { addNavigateBackListener, back } = useNavigateBack();
  const [referrerLocation, setReferrerLocation] = useState<string | undefined>();
  const [signUpUsername, setSignUpUsername] = useState('');
  const [eventPageName, setEventPageName] = useState<string | undefined>();

  const location = useLocation();
  const navigate = useNavigate();
  const disallowedUrls = useDisallowedUrls();

  const enhancedNavigate = useEnhancedNavigate(basename);

  const value = useMemo<ILocation>(
    () => ({
      addHardwareBackButtonListener: noop,
      addNavigateBackListener,
      appUrl: appUrl(basename),
      back,
      eventPageName,
      location,
      navigate: enhancedNavigate,
      pathMatches: getPathMatcher(basename),
      // TODO(ICFE-877): Remove when enhanced navigate support reload
      redirect: createRedirectHandler(basename),
      referrerLocation,
      removeHardwareBackButtonListener: noop,
      setEventPageName,
      setReferrerLocation,
      setSignUpUsername,
      setStoreLocatorCallbackUrl,
      signUpUsername,
      storeLocatorCallbackUrl,
    }),
    [
      addNavigateBackListener,
      back,
      eventPageName,
      location,
      navigate,
      referrerLocation,
      signUpUsername,
      storeLocatorCallbackUrl,
    ]
  );

  return (
    <LocationContext.Provider value={value}>
      {disallowedUrls.includes(location.pathname) && <Meta name="robots" content="none" />}
      {children}
    </LocationContext.Provider>
  );
}

export const Location = LocationContext.Consumer;
