import { createSelector } from '@reduxjs/toolkit';

import { OfferRedemptionType } from 'generated/graphql-gateway';
import { IUserAttributes } from 'generated/sanity-graphql';
import { RootState } from 'state/global-state';
import { IncentiveEvaluationErrorCodes } from 'state/loyalty/hooks/types';
import { LoyaltyOffer, RuleType, UserAttributesItem } from 'state/loyalty/types';
import { getCmsOffersMapByCmsId, isDiscountLoyaltyOffer } from 'state/loyalty/utils';
import { DIFFERENCE_TIME_UNITS, getDifferenceToNow } from 'utils/dateTime';

import { incentiveErrorsFilteredList } from './offers.utils';

export const selectAppliedOffers = ({ loyalty }: RootState) => {
  return loyalty.offers.appliedOffers;
};

export const selectCartEntriesIdsMap = ({ loyalty }: RootState) => {
  return loyalty.offers.cartEntriesIdsMap;
};

export const selectOfferFeedbackMap = ({ loyalty }: RootState) => {
  return loyalty.offers.offersFeedbackMap;
};

export const selectOffersLoading = ({ loyalty }: RootState) => {
  return loyalty.offers.offersLoading;
};

export const selectOffers = ({ loyalty }: RootState) => {
  return loyalty.offers.offers;
};

export const selectUserOffers = ({ loyalty }: RootState) => {
  return loyalty.offers.userOffers;
};

export const selectPersonalizedOffers = ({ loyalty }: RootState) => {
  return loyalty.offers.personalizedOffers;
};

export const selectSurpriseAvailable = ({ loyalty }: RootState) => {
  return loyalty.offers.surpriseAvailable;
};

export const selectUpsizeAvailable = ({ loyalty }: RootState) => {
  return loyalty.offers.upsizeAvailable;
};

export const selectSelectedOffer = ({ loyalty }: RootState) => {
  return loyalty.offers.selectedOffer;
};

export const selectIncentiveErrorsFilteredByCode = (
  { loyalty }: RootState,
  errorCodeToFilter: IncentiveEvaluationErrorCodes
) => {
  return incentiveErrorsFilteredList(
    loyalty.offers.offersFeedbackMap,
    evaluationError => evaluationError.code !== errorCodeToFilter
  );
};

export const selectIncentiveErrorsWithCode = (
  { loyalty }: RootState,
  errorCodeToFilter: IncentiveEvaluationErrorCodes
) => {
  return incentiveErrorsFilteredList(
    loyalty.offers.offersFeedbackMap,
    evaluationError => evaluationError.code === errorCodeToFilter
  );
};

export const selectCmsOffers = ({ loyalty }: RootState) => {
  return loyalty.offers.cmsOffers;
};

export const selectCmsSurpriseOffers = ({ loyalty }: RootState) => {
  return loyalty.offers.cmsOffers.filter(
    offer => offer.redemptionType === OfferRedemptionType.SURPRISE
  );
};

export const selectAppliedCmsOffers = ({ loyalty }: RootState) => {
  const set = new Set(loyalty.offers.appliedOffers.map(({ id }) => id));
  return loyalty.offers.cmsOffers?.filter(cmsOffer => set.has(cmsOffer.loyaltyEngineId));
};

export const selectAppliedPersonalizedOffers = ({ loyalty }: RootState) => {
  const set = new Set(loyalty.offers.appliedOffers.map(({ id }) => id));
  return loyalty.offers.personalizedOffers?.filter(personalizedOffers =>
    set.has(personalizedOffers.loyaltyEngineId)
  );
};

export const selectDiscountAppliedCmsOffer = createSelector(
  selectAppliedCmsOffers,
  appliedCmsOffers =>
    //Return first match because there should never be two applied offer discounts
    appliedCmsOffers?.find(isDiscountLoyaltyOffer)
);

export const selectDiscountAppliedPersonalizedOffer = createSelector(
  selectAppliedPersonalizedOffers,
  //Return first match because there should never be two applied offer discounts
  appliedPersonalizedOffers => appliedPersonalizedOffers?.find(isDiscountLoyaltyOffer)
);

export const selectLoyaltyOffersInCooldown = ({ loyalty }: RootState) => {
  return loyalty.offers.offerRedemptionAvailableAfter
    ? getDifferenceToNow(
        DIFFERENCE_TIME_UNITS.SECONDS,
        loyalty.offers.offerRedemptionAvailableAfter
      ) > 0
    : false;
};

export const selectExtendedCmsOffersMap =
  ({ loyalty }: RootState) =>
  (newCmsOffers: LoyaltyOffer[]) => {
    const cmsOffersMap = getCmsOffersMapByCmsId(loyalty.offers.cmsOffers);
    const newCmsOffersMap = getCmsOffersMapByCmsId(newCmsOffers);

    return { ...cmsOffersMap, ...newCmsOffersMap };
  };

export const selectValidAppliedOffers =
  ({ loyalty }: RootState) =>
  (offersMap: { [key: string]: LoyaltyOffer }) => {
    return loyalty.offers.appliedOffers.filter(
      offer => !!offer.swap || !!offersMap[offer.cmsId || '']
    );
  };

export const selectIncentivesIds = ({ loyalty }: RootState) => {
  return new Set(loyalty.offers.incentivesIds);
};

export const selectAppliedOffersAtCheckoutMap = ({ loyalty }: RootState) =>
  loyalty.offers.appliedOffers.reduce<Record<string, boolean>>((acc, curr) => {
    if (curr.id && curr.isAppliedAtCheckout) {
      acc[curr.id] = true;
    }
    return acc;
  }, {});

export const selectBatteryOfferAvailable = ({ loyalty }: RootState): Boolean => {
  return loyalty.offers.cmsOffers.some(offer => {
    const userAttributes = offer.rules?.find(rule => rule?.__typename === RuleType.UserAttributes);
    if (!userAttributes) {
      return false;
    }
    const { userAttributesItem } = userAttributes as IUserAttributes;
    const batteryRules = userAttributesItem?.find(
      attribute => attribute?.attributeItem === UserAttributesItem.BatteryLevel
    );
    return Boolean(batteryRules);
  });
};
