import React from 'react';

import { useIntl } from 'react-intl';
import styled from 'styled-components';

const SkipToContentContainer = styled.a`
  position: fixed;
  color: ${Styles.color.accent};
  text-decoration: none;
  inset-block-start: -99999px;
  inset-inline-start: -99999px;
  opacity: 0;

  &:focus {
    inset-block-start: 0.2rem;
    inset-inline-start: 0.2rem;
    opacity: 1;
  }
`;

export const SkipToContent = () => {
  const { formatMessage } = useIntl();

  return (
    <SkipToContentContainer href="#main" tabIndex="0" data-testid="skip-to-content">
      {formatMessage({ id: 'skipToContent' })}
    </SkipToContentContainer>
  );
};
