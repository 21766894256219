import React, { Ref, useEffect, useState } from 'react';

import { PrimaryButton, SecondaryButton } from '@rbilabs/components-library';
import { Icon } from '@rbilabs/components-library/build/components/icon';
import { format } from 'date-fns';
import { useIntl } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';

import { ActionButtonVariants } from 'components/action-button';
import { ClickableContainer } from 'components/clickable-container';
import { DialogMemo } from 'components/dialog';
import { PickupTimeSelector } from 'components/pickup-time-selector';
import { StoreCardIcons } from 'components/store-card-icons';
import { IStoreDiagnosticData } from 'components/store-diagnostic-modal';
import { RbiOrderStatus, useGetUserOrderQuery } from 'generated/rbi-graphql';
import { useStoreCard } from 'hooks/store-card';
import { useServiceModeStatus } from 'hooks/use-service-mode-status';
import { useGeolocation } from 'state/geolocation';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { ServiceMode, useOrderContext } from 'state/order';
import { useOrderEdit } from 'state/order/hooks/use-order-edit';
import { nextOpenDay, readableHour } from 'utils/restaurant';
import { toast } from 'utils/toast';

import { DefaultHoursText } from './default-hours-text';
import { StoreCardFooter } from './store-card-footer';
import {
  AddressHeader,
  AddressSubheader,
  ButtonGroupsCancelDialog,
  DirectionsLinkStyled,
  Divider,
  EditOrderContainer,
  NewLayoutButton,
  NewLayoutCancelButtonContainer,
  NewLayoutCancelWrapper,
  NewLayoutDetailsContainer,
  NewLayoutDetailsSpan,
  NewLayoutDetailsWrapper,
  NewLayoutDetailsWrapperSpan,
  NewLayoutDismissButton,
  NewLayoutDivider,
  NewLayoutEditSpan,
  NewLayoutSaveCancelContainer,
  NewLayoutSaveWrapper,
  SelectedBorder,
  SmallText,
  StoreCardContainer,
  StoreCardLeftContainer,
  StoreCardRightContainer,
  StyledCancelButton,
  TextCancelModal,
} from './styled';
import { IStoreCardProps } from './types';

export const StoreCard = React.forwardRef(
  (
    {
      className,
      HoursText = DefaultHoursText,
      isSelected,
      pickupDate,
      onClick,
      Footer = StoreCardFooter,
      isListView = false,
      restaurant,
      newLayout = false,
      onOrderRefunded,
    }: IStoreCardProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const {
      distanceText,
      closeHourTodayText,
      diningRoomOpen,
      driveThruOpen,
      openHours,
      selectRestaurant,
      isMobileOrderingAvailable,
      isRestaurantPosOnline,
      areDiagnosticToolsEnabled,
    } = useStoreCard({ restaurant });
    const { activeCoordinates } = useGeolocation();
    const { language } = useLocale();
    const { formatMessage } = useIntl();
    const [onEdit, setOnEdit] = useState(false);
    const { restaurantCanBeSelected } = useServiceModeStatus(restaurant);
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [openEditPickupTimeModal, setOpenEditPickupTimeModal] = useState(false);
    const [openErrorPickupTimeModal, setOpenErrorPickupTimeModal] = useState(false);
    const [openInfoModal, setOpenInfoModal] = useState(false);
    const order = useOrderContext();
    const { serverOrder } = useOrderContext();
    const { rbiOrderId } = serverOrder;
    const { cancelOrder, serviceMode, fireOrderInXSeconds, fireOrderIn, isUpdatingOrder } = order;
    const { canOrderBeEdited } = useOrderEdit({ pickupDate });
    const { rbiOrderId: orderId } = useParams();
    const id = rbiOrderId ?? orderId;
    const [pollingInProgress, setPollingInProgress] = useState(false);

    const enablePickupTimeModification = useFlag(
      LaunchDarklyFlag.ENABLE_SCHEDULED_PICKUP_TIME_MODIFICATION
    );

    const showButtonsPickUpTimeModification = () =>
      enablePickupTimeModification && serviceMode !== ServiceMode.DRIVE_THRU;

    const handleEdit = () => {
      setOnEdit(true);
    };

    const cancelEdit = () => {
      setOnEdit(false);
    };

    const handleSave = () => {
      setOnEdit(false);
      const orderCreatedAt = orderData?.order?.createdAt;

      // if order created, we need to check if the pickup date
      // is not less than the current time
      // when user try to save the time
      if (orderCreatedAt) {
        const dateNow = new Date();
        const dateAfter = new Date(orderCreatedAt);

        dateAfter.setSeconds(dateAfter.getSeconds() + fireOrderIn);

        if (dateAfter > dateNow) {
          // execute UpdateOrderMutation
          fireOrderInXSeconds({ rbiOrderId: rbiOrderId ?? orderId, timeInSeconds: fireOrderIn });
        } else {
          setOpenErrorPickupTimeModal(true);
        }
      } else {
        if (canOrderBeEdited()) {
          fireOrderInXSeconds({ rbiOrderId: rbiOrderId ?? orderId, timeInSeconds: fireOrderIn });
          toast.success(formatMessage({ id: 'successOrderModificationTime' }));
        } else {
          toast.error(formatMessage({ id: 'changePickupNotPossible' }));
        }
      }
      handleCloseEditPickupTimeModal();
    };

    const handleCancel = () => {
      setOnEdit(false);
      setOpenCancelModal(true);
    };

    const handleCloseCancelModal = () => {
      setOpenCancelModal(false);
    };

    const handleEditPickupTimeModal = () => {
      const orderCreatedAt = orderData?.order?.createdAt;
      if (!orderCreatedAt) {
        return;
      }

      const dateNow = new Date();
      const dateAfter = new Date(orderCreatedAt);

      dateAfter.setSeconds(dateAfter.getSeconds() + fireOrderIn);
      dateNow.setMinutes(dateNow.getMinutes() + 30);

      const dateIsLessThanThirtyMinutes = dateNow > dateAfter;

      if (dateIsLessThanThirtyMinutes) {
        setOpenEditPickupTimeModal(true);
      } else {
        handleSave();
      }
    };

    const handleCloseEditPickupTimeModal = () => {
      setOpenEditPickupTimeModal(false);
    };

    const {
      data: orderData,
      startPolling: startPollingOrderData,
      stopPolling: stopPollingOrderData,
    } = useGetUserOrderQuery({
      variables: {
        id,
      },
    });

    const REFUND_END_STATUSES = [RbiOrderStatus.REFUND_SUCCESSFUL, RbiOrderStatus.REFUND_ERROR];
    const { status: orderStatus } = orderData?.order ?? {};

    const shouldPollOrderData = orderStatus === RbiOrderStatus.REFUND_REQUESTED;
    const shouldStopPollingOrderData = orderStatus && REFUND_END_STATUSES.includes(orderStatus);
    const isDriveThru = serviceMode === ServiceMode.DRIVE_THRU;

    const startPollingOrder = () => {
      setPollingInProgress(true);
      startPollingOrderData(500);
      setOpenCancelModal(false);
    };

    const stopPollingOrder = () => {
      setPollingInProgress(false);
      stopPollingOrderData();
      onOrderRefunded();
    };

    useEffect(() => {
      if (shouldPollOrderData) {
        startPollingOrder();
      } else if (shouldStopPollingOrderData) {
        stopPollingOrder();
      }
    }, [
      shouldPollOrderData,
      shouldStopPollingOrderData,
      orderStatus,
      orderData,
      startPollingOrder,
      stopPollingOrder,
    ]);

    const onCompletedCancelOrder = () => {
      startPollingOrder();
    };

    const handleSubmitCancelModal = async () => {
      if (canOrderBeEdited()) {
        await cancelOrder({
          rbiOrderId: rbiOrderId ?? orderId,
          onCompleted: onCompletedCancelOrder,
        });
      } else {
        toast.error(formatMessage({ id: 'changePickupNotPossible' }));
      }
    };

    const customerFacingAddress = restaurant.customerFacingAddress
      ? restaurant.customerFacingAddress[language]
      : undefined;
    const address = customerFacingAddress || restaurant.physicalAddress?.address1;

    const storeDiagnosticData: IStoreDiagnosticData = {
      driveThruOpen,
      diningRoomOpen,
      isRestaurantOpen: restaurantCanBeSelected,
      isMobileOrderingAvailable,
      isRestaurantPosOnline,
      restaurantName: restaurant.name || '',
      restaurantHasMobileOrdering: restaurant.hasMobileOrdering,
      restaurantCountry: restaurant.physicalAddress.country || '',
      restaurantStreetAddress: address || '',
    };

    const position = { lat: restaurant.latitude, lng: restaurant.longitude };

    if (!restaurant || !restaurant.number) {
      return null;
    }

    return (
      <ClickableContainer className={className} ref={ref} onClick={onClick}>
        <StoreCardContainer
          isListView={isListView}
          style={{ borderRadius: newLayout ? '12px' : 'none' }}
        >
          {isSelected && <SelectedBorder />}
          <AddressHeader areDiagnosticsEnabled={areDiagnosticToolsEnabled}>
            <h2 data-private data-dd-privacy="mask" data-testid="restaurant-address">
              {address || ''}
            </h2>
          </AddressHeader>

          <AddressSubheader>
            <p data-private data-dd-privacy="mask">
              {(restaurant?.physicalAddress?.city || '').toLowerCase()}{' '}
              {restaurant?.physicalAddress?.stateProvince || ''},{' '}
              {(restaurant?.physicalAddress?.postalCode || '').split('-')[0]}{' '}
            </p>
          </AddressSubheader>

          <SmallText>
            {distanceText && (
              <>
                {distanceText} <span role="separator">•</span>{' '}
              </>
            )}
            {!newLayout && (
              <HoursText
                closeHourToday={closeHourTodayText}
                nextOpenHour={openHours ? readableHour(nextOpenDay(openHours)) : ''}
                isOpen={driveThruOpen || diningRoomOpen}
              />
            )}
          </SmallText>

          {isListView && (
            <DirectionsLinkStyled activeCoordinates={activeCoordinates} position={position} />
          )}
          <Divider />

          {newLayout && !isDriveThru && <NewLayoutDivider />}

          <Footer
            restaurant={restaurant}
            selectRestaurant={selectRestaurant}
            isRestaurantSelectable={restaurantCanBeSelected}
          />
          <StoreCardIcons
            storeNumber={restaurant.number ?? ''}
            storeId={restaurant._id ?? ''}
            diagnosticsEnabled={areDiagnosticToolsEnabled}
            storeDiagnosticData={storeDiagnosticData}
          />
          {!newLayout && (
            <EditOrderContainer>
              <StoreCardLeftContainer>
                {showButtonsPickUpTimeModification() && !onEdit && (
                  <SecondaryButton
                    size={'small'}
                    fullWidth={true}
                    disabled={!canOrderBeEdited() || pollingInProgress}
                    data-testid="edit-order"
                    aria-label={formatMessage({ id: 'editPickUpTime' })}
                    onClick={handleEdit}
                  >
                    {formatMessage({ id: 'editPickUpTime' })}
                  </SecondaryButton>
                )}
                {onEdit && (
                  <PrimaryButton
                    size={'small'}
                    fullWidth={true}
                    aria-label={formatMessage({ id: 'saveOrder' })}
                    data-testid="save-order"
                    onClick={handleEditPickupTimeModal}
                  >
                    {formatMessage({ id: 'save' })}
                  </PrimaryButton>
                )}
              </StoreCardLeftContainer>
              <StoreCardRightContainer>
                {(!canOrderBeEdited() || pollingInProgress) &&
                  showButtonsPickUpTimeModification() && (
                    <SecondaryButton
                      size={'small'}
                      fullWidth={true}
                      disabled={!canOrderBeEdited() || pollingInProgress}
                      data-testid="cancel-order"
                      aria-label={formatMessage({ id: 'cancelOrder' })}
                      loading={pollingInProgress}
                      onClick={handleCancel}
                    >
                      {formatMessage({ id: 'cancelOrder' })}
                    </SecondaryButton>
                  )}
              </StoreCardRightContainer>
            </EditOrderContainer>
          )}

          {newLayout && !isDriveThru && (
            <NewLayoutDetailsContainer>
              <NewLayoutDetailsWrapper>
                <AddressHeader>
                  {!isUpdatingOrder ? (
                    <h2 data-private data-dd-privacy="mask" data-testid="restaurant-address">
                      {format(pickupDate, 'h:mm aaaa').replace(' ', String.fromCharCode(160)) || ''}
                    </h2>
                  ) : (
                    <Skeleton height="20px" width="100px" />
                  )}
                </AddressHeader>

                <AddressSubheader>
                  <NewLayoutDetailsWrapperSpan>
                    <NewLayoutDetailsSpan>
                      {formatMessage({ id: 'pickUpTime' })}
                    </NewLayoutDetailsSpan>
                    <div onClick={() => setOpenInfoModal(true)} aria-hidden="true">
                      <Icon icon="info" color="icon-default" width="16px" aria-hidden />
                    </div>
                  </NewLayoutDetailsWrapperSpan>
                </AddressSubheader>
              </NewLayoutDetailsWrapper>

              {canOrderBeEdited() &&
              showButtonsPickUpTimeModification() &&
              !onEdit &&
              !isUpdatingOrder ? (
                <NewLayoutButton onClick={handleEdit}>
                  <NewLayoutEditSpan>{formatMessage({ id: 'edit' })}</NewLayoutEditSpan>
                  <Icon icon="edit" color="icon-button-tertiary-default" width="16px" aria-hidden />
                </NewLayoutButton>
              ) : (
                isUpdatingOrder && <Skeleton height="35px" width="75px" />
              )}
            </NewLayoutDetailsContainer>
          )}

          {newLayout && onEdit && (
            <div style={{ marginTop: '16px' }}>
              <PickupTimeSelector
                data-testid="edit-order-pickup-time-selector"
                showAsTime
                onChange={order.setFireOrderIn}
                value={order.fireOrderIn}
                scheduledTime={pickupDate}
                createdAt={orderData?.order?.createdAt}
              />
            </div>
          )}

          {newLayout && (
            <NewLayoutSaveCancelContainer>
              <NewLayoutCancelWrapper>
                {onEdit && (
                  <SecondaryButton
                    size={'small'}
                    fullWidth={true}
                    aria-label={formatMessage({ id: 'cancel' })}
                    data-testid="cancel-change"
                    onClick={cancelEdit}
                  >
                    {formatMessage({ id: 'cancel' })}
                  </SecondaryButton>
                )}
              </NewLayoutCancelWrapper>
              <NewLayoutSaveWrapper>
                {onEdit && (
                  <PrimaryButton
                    size={'small'}
                    fullWidth={true}
                    aria-label={formatMessage({ id: 'saveOrder' })}
                    data-testid="save-change"
                    onClick={handleEditPickupTimeModal}
                  >
                    {formatMessage({ id: 'save' })}
                  </PrimaryButton>
                )}
              </NewLayoutSaveWrapper>
            </NewLayoutSaveCancelContainer>
          )}

          {openCancelModal && (
            <DialogMemo
              data-testid="cancel-order-dialog"
              heading={formatMessage({ id: 'cancelOrder' })}
              body={
                <ButtonGroupsCancelDialog>
                  <TextCancelModal>{formatMessage({ id: 'cancelOrderDialog' })}</TextCancelModal>
                  <PrimaryButton
                    fullWidth
                    aria-label={formatMessage({ id: 'noCancelOrderDialog' })}
                    onClick={handleCloseCancelModal}
                    data-testid="no-cancel-order-dialog-button"
                  >
                    {formatMessage({ id: 'noCancelOrderDialog' })}
                  </PrimaryButton>
                  <SecondaryButton
                    fullWidth
                    aria-label={formatMessage({ id: 'yesCancelOrderDialog' })}
                    onClick={handleSubmitCancelModal}
                    data-testid="yes-cancel-order-dialog-button"
                  >
                    {formatMessage({ id: 'yesCancelOrderDialog' })}
                  </SecondaryButton>
                </ButtonGroupsCancelDialog>
              }
              modalAppearanceEventMessage="cancel-order-dialog"
            />
          )}
          {openEditPickupTimeModal && (
            <DialogMemo
              data-testid="change-pickup-time-order-dialog"
              heading={formatMessage({ id: 'changePickupTime' })}
              body={
                <ButtonGroupsCancelDialog>
                  <TextCancelModal>
                    {formatMessage({ id: 'changePickupTimeDetailsLessThanThirtyMinutes' })}
                  </TextCancelModal>
                  <PrimaryButton
                    fullWidth
                    aria-label={formatMessage({ id: 'yesChangeIt' })}
                    onClick={handleSave}
                    data-testid="change-time-pickup"
                  >
                    {formatMessage({ id: 'yesChangeIt' })}
                  </PrimaryButton>
                  <SecondaryButton
                    fullWidth
                    aria-label={formatMessage({ id: 'cancel' })}
                    onClick={handleCloseEditPickupTimeModal}
                    data-testid="no-change-time-pickup"
                  >
                    {formatMessage({ id: 'cancel' })}
                  </SecondaryButton>
                </ButtonGroupsCancelDialog>
              }
              modalAppearanceEventMessage="cancel-order-dialog"
            />
          )}
          {openErrorPickupTimeModal && (
            <DialogMemo
              data-testid="error-order-dialog"
              heading={formatMessage({ id: 'changePickupTimeError' })}
              body={
                <ButtonGroupsCancelDialog>
                  <TextCancelModal>
                    {formatMessage({ id: 'changePickupTimeErrorDetails' })}
                  </TextCancelModal>
                  <PrimaryButton
                    fullWidth
                    aria-label={formatMessage({ id: 'backToYourOrder' })}
                    onClick={() => {
                      setOpenErrorPickupTimeModal(false);
                    }}
                    data-testid="back-to-your-order-button"
                  >
                    {formatMessage({ id: 'backToYourOrder' })}
                  </PrimaryButton>
                </ButtonGroupsCancelDialog>
              }
              modalAppearanceEventMessage="error-order-dialog"
            />
          )}
          {openInfoModal && (
            <DialogMemo
              data-testid="info-dialog"
              heading={formatMessage({ id: 'pickupTimeChangeInfo' })}
              body={
                <ButtonGroupsCancelDialog>
                  <TextCancelModal>{formatMessage({ id: 'changePickupTimeInfo' })}</TextCancelModal>
                  <NewLayoutDismissButton
                    data-testid="got-it-button"
                    variant={ActionButtonVariants.TEXT_ONLY}
                    onClick={() => {
                      setOpenInfoModal(false);
                    }}
                  >
                    {formatMessage({ id: 'gotIt' })}
                  </NewLayoutDismissButton>
                </ButtonGroupsCancelDialog>
              }
              modalAppearanceEventMessage="info-dialog"
            />
          )}
        </StoreCardContainer>
        {newLayout && canOrderBeEdited() && (
          <NewLayoutCancelButtonContainer>
            <StyledCancelButton
              loadingColor={Styles.color.tertiary}
              disabled={!canOrderBeEdited() || pollingInProgress}
              data-testid="cancel-order"
              aria-label={formatMessage({ id: 'cancelOrder' })}
              isLoading={pollingInProgress}
              onClick={handleCancel}
            >
              {formatMessage({ id: 'cancelOrder' })}
            </StyledCancelButton>
          </NewLayoutCancelButtonContainer>
        )}

        {!newLayout && onEdit && (
          <PickupTimeSelector
            data-testid="edit-order-pickup-time-selector"
            showAsTime
            onChange={order.setFireOrderIn}
            value={order.fireOrderIn}
            scheduledTime={pickupDate}
            createdAt={orderData?.order?.createdAt}
          />
        )}
      </ClickableContainer>
    );
  }
);
