export const storeMarkerFhs = `
<svg width="48" height="60" viewBox="0 0 48 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="path-1-inside-1_494_9922" fill="white">
<path fill-rule="evenodd" clip-rule="evenodd" d="M31.0326 46.9532C40.856 43.9471 48 34.8078 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 34.3472 6.54807 43.1651 15.7264 46.5358L23.5 60L31.0326 46.9532Z"/>
</mask>
<path fill-rule="evenodd" clip-rule="evenodd" d="M31.0326 46.9532C40.856 43.9471 48 34.8078 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 34.3472 6.54807 43.1651 15.7264 46.5358L23.5 60L31.0326 46.9532Z" fill="#FF7D00"/>
<path d="M31.0326 46.9532L30.1547 44.0845L29.0252 44.4302L28.4345 45.4532L31.0326 46.9532ZM15.7264 46.5358L18.3245 45.0358L17.781 44.0944L16.7606 43.7197L15.7264 46.5358ZM23.5 60L20.9019 61.5L23.5 66L26.0981 61.5L23.5 60ZM31.9104 49.8219C42.9593 46.4408 51 36.1633 51 24H45C45 33.4524 38.7526 41.4534 30.1547 44.0845L31.9104 49.8219ZM51 24C51 9.08831 38.9117 -3 24 -3V3C35.598 3 45 12.402 45 24H51ZM24 -3C9.08831 -3 -3 9.08831 -3 24H3C3 12.402 12.402 3 24 3V-3ZM-3 24C-3 35.6454 4.37106 45.5615 14.6922 49.3519L16.7606 43.7197C8.72508 40.7687 3 33.0491 3 24H-3ZM13.1284 48.0358L20.9019 61.5L26.0981 58.5L18.3245 45.0358L13.1284 48.0358ZM26.0981 61.5L33.6307 48.4532L28.4345 45.4532L20.9019 58.5L26.0981 61.5Z" fill="white" mask="url(#path-1-inside-1_494_9922)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M28.8522 11.9175C29.1459 11.7662 29.5223 11.914 29.6911 12.2429C29.86 12.5735 29.758 12.964 29.4642 13.1135C29.1705 13.263 28.7941 13.1188 28.6253 12.7881C28.4564 12.4574 28.5584 12.067 28.8522 11.9175ZM11 19.2096L13.5855 22.3298C13.5855 22.3298 14.9679 20.2983 16.6722 21.6614C17.9803 22.7076 17.582 24.5992 17.2553 26.1506C17.1563 26.6206 17.0639 27.0593 17.0275 27.4339C16.8288 29.4847 17.3336 31.1433 18.1585 32.4607C19.2085 34.1368 21.6462 35.5316 24.2634 36.0258C24.4639 36.0628 24.6556 36.0909 24.8455 36.1155L25.1182 39H27.0898L27.3554 36.1806C31.8809 35.8165 38.283 31.3895 35.4337 23.9338C34.1234 20.5041 31.3462 15.3824 31.3462 15.3824L31.3779 15.3736C32.4771 15.0939 32.6899 13.6675 31.406 12.9024L32.3241 12.0529L30.9821 11.5024L31.4834 9.66792L28.6007 10.8094L28.6095 10.8024C28.6095 10.8024 27.8795 9.27922 26.768 9.26691C25.8323 9.25635 25.3908 9.9335 25.3908 9.9335C25.3908 9.9335 25.141 9.23701 24.5008 9.04705C23.8606 8.8571 22.8159 9.22997 22.6839 10.3926C22.5362 11.6959 23.588 12.366 23.9345 12.4768C23.966 12.4869 23.9985 12.4978 24.0321 12.5091C24.4368 12.6449 25.0054 12.8357 26.1049 12.3712L26.1014 14.4332C26.0994 15.6082 26.0971 16.9102 26.0961 17.7322C26.0956 17.9919 26.0985 18.2749 26.1016 18.5737C26.1202 20.353 26.1446 22.6953 25.4805 24.0552C24.7048 25.6434 23.8219 26.3047 22.6523 26.1675C21.3455 26.0145 20.8987 24.7904 21.3525 22.5197C21.8063 20.2491 22.1897 17.989 20.2866 16.3058C18.3836 14.6226 14.9539 14.4361 11 19.2096ZM29.7756 38.7712L29.5205 38.189H29.3816V38.9998H29.4871V38.3491L29.7245 38.8908H29.8266L30.0622 38.3491V38.9998H30.1678V38.189H30.0271L29.7756 38.7712ZM28.9208 38.291H28.6833V38.189H29.2673V38.291H29.0298V38.9998H28.9208V38.291Z" fill="white"/>
</svg>
`;
