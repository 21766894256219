import * as React from 'react';
import { FC } from 'react';

import { ISanityTextNode } from '@rbi-ctg/menu';

import { StyledText } from './loyalty-text-widget.styled';

export interface ITextWidgetProps {
  text: ISanityTextNode;
  className?: string;
}

export const LoyaltyTextWidget: FC<ITextWidgetProps> = ({ text, className }: ITextWidgetProps) => {
  return <StyledText className={className}>{text.locale}</StyledText>;
};
