import React from 'react';

import { useIntl } from 'react-intl';
import styled from 'styled-components';

export const StyledChooseLocation = styled.button`
  font-size: 0.875rem;
  text-decoration: underline;
  color: ${Styles.color.contrastBackground};
  ${Styles.desktop} {
    font-size: 0.983rem;
  }

  &:hover {
    text-decoration: none;
  }

  border: none;
  background: none;
  cursor: pointer;
  margin-block-end: 0;
  margin-inline-end: 0;
  padding: 0;
  font-family: ${Styles.fontFamily.body};
`;

export const ChooseLocation = () => {
  const { formatMessage } = useIntl();
  return <StyledChooseLocation>{formatMessage({ id: 'chooseYourLocation' })}</StyledChooseLocation>;
};
