import { appVersionCode, brand, env, getCountry, platform } from 'utils/environment';

import { TContext } from './types';

export const globalContext = {
  appVersionCode: appVersionCode() as string,
  stage: env(),
  brand: brand(),
  country: getCountry() as string,
  platform: platform(),
} as const;

/**
 * Clears undefined attributes.
 */
const clearAttributes = (attributes: TContext) =>
  Object.entries(attributes).reduce((acc, [key, value]) => {
    return value === undefined ? acc : { ...acc, [key]: value };
  }, {});

interface IContextManager {
  getContext: () => TContext;
  setContext: (newContext: Partial<TContext>) => void;
}

/**
 * Context manager for logger.
 *
 * The context manager allows to get and update contexts used by the logger.
 */
export const createContextManager = <T extends TContext>(initialContext: T): IContextManager => {
  let context = clearAttributes(initialContext);

  return {
    getContext: () => context,
    setContext: (newContext: Partial<T>) => {
      context = clearAttributes({ ...context, ...newContext });
    },
  };
};
