import React from 'react';

import { IMarketingTileBasic, IMarketingTileBasicFragment, Maybe } from 'generated/sanity-graphql';

import { MarketingTileListElement } from './marketing-tile-list-element';

interface MarketingTileListProps {
  tiles: readonly Maybe<IMarketingTileBasic>[] | null;
}

export const MarketingTileList = ({ tiles }: MarketingTileListProps) => {
  if (!tiles || tiles.length === 0) {
    return null;
  }
  const tileListElements = tiles.map(tile => {
    const basicTile = tile as IMarketingTileBasicFragment;
    return (
      <MarketingTileListElement key={`MarketingTileList_${basicTile._id}`} basicTile={basicTile} />
    );
  });

  return <div data-testid="marketing-tile-list-container">{tileListElements}</div>;
};
