import * as React from 'react';

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ISerializers, SanityBlockContentLinkProps } from 'components/sanity-block-renderer';
import { isExternalLink } from 'utils/is-external-link';

const StyledLink = styled(Link)`
  color: ${Styles.color.black};
`;

export const linkOptions = ({ children = [], mark = {} }: SanityBlockContentLinkProps) => {
  const [linkText] = children;
  const { href } = mark;

  if (!href || !linkText) {
    return null;
  }

  const externalLinkProps = isExternalLink(href)
    ? {
        rel: 'noopener noreferrer',
        target: '_blank',
      }
    : {};

  return (
    <StyledLink to={href} {...externalLinkProps}>
      {linkText}
    </StyledLink>
  );
};

export const linkSerializers: ISerializers = {
  marks: {
    link: linkOptions,
  },
};
