import type { AppInfo } from '@capacitor/app';
import { SyncResult } from '@capacitor/live-updates';

import { appVersionCode } from 'utils/environment';

const alwaysFalse = () => false;

export const askPermissionOnCritialRoute = alwaysFalse;

export const getShouldReloadApp = alwaysFalse;

export const getCurrentVersion = (): Promise<AppInfo & { appflowBuildId: string }> =>
  Promise.resolve({
    version: 'web',
    build: appVersionCode(),
    id: '',
    name: '',
    appflowBuildId: '',
  });

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const enable = (shouldUseRbiLiveUpdates: boolean) => Promise.resolve();

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const sync = (shouldUseRbiLiveUpdates: boolean): Promise<SyncResult> =>
  Promise.resolve({
    activeApplicationPathChanged: false,
    liveUpdate: {
      appId: 'web',
      channel: 'none',
    },
    snapshot: null,
    source: 'download',
  });

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const reload = (shouldUseRbiLiveUpdates: boolean) => Promise.resolve();

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const forceUpdate = (shouldUseRbiLiveUpdates: boolean) => Promise.resolve();

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const backgroundUpdate = (shouldUseRbiLiveUpdates: boolean) => Promise.resolve();
