import React, { useCallback, useEffect, useState } from 'react';

import { datadogRum } from '@datadog/browser-rum';

import { useCdpContext } from 'state/cdp';
import { CdpProviderTypes } from 'state/cdp/types';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { getConfigValue } from 'utils/environment';
import { LocalStorage, StorageKeys } from 'utils/local-storage';

import { useInitializeThirdPartyServices } from '../use-initialize-third-party-service';

import { isBranchUnavailable } from './isBranchUnavailable';
import { ConsentGroups, OneTrustConsentCategories } from './types';
import { useOneTrustSdk } from './use-one-trust-sdk';
import {
  getAllServicesInitialized,
  handleBloomreachConsent,
  handleOTMparticleConsent,
} from './utils';

export const OneTrustCookieBanner = () => {
  const { loaded: otSdkLoaded } = useOneTrustSdk();

  const [servicesLoaded, setServiceLoaded] = useState(false);
  const [otConsentGroups, setConsentGroups] = useState({} as ConsentGroups);
  const [consentGroupsSetInLocalStorage, setConsentGroupsSetInLocalStorage] = useState(false);
  const branchUnavailable = isBranchUnavailable();
  const cdpProvider = useFlag(LaunchDarklyFlag.CDP_PROVIDER);
  const { initServicesWithCookies } = useInitializeThirdPartyServices();
  const { trackEvent } = useCdpContext();

  // loads 3rd party services once OneTrust sdk is loaded
  useEffect(() => {
    if (otSdkLoaded && !servicesLoaded) {
      initServicesWithCookies();
    }
  }, [initServicesWithCookies, otConsentGroups, otSdkLoaded, servicesLoaded]);

  const servicesInitCheck = useCallback(() => {
    const branchValidation = branchUnavailable || !!window.branch;
    const areServicesInitialized = getAllServicesInitialized(
      cdpProvider as CdpProviderTypes,
      branchValidation
    );
    if (areServicesInitialized) {
      setServiceLoaded(true);
    }
    return areServicesInitialized;
  }, [branchUnavailable, cdpProvider]);

  // Sets a interval to check if all 3rd party services are initialized
  useEffect(() => {
    const id = setInterval(servicesInitCheck, 1000);
    if (servicesLoaded) {
      clearInterval(id);
    }
    return () => clearInterval(id);
  }, [servicesInitCheck, servicesLoaded]);

  const getConsentGroups = (consentState: String[]) => {
    const groups: ConsentGroups = {
      C0001: 0,
      C0002: 0,
      C0003: 0,
      C0004: 0,
    };

    return Object.keys(groups).reduce((accumulator, group) => {
      if (consentState.includes(group)) {
        accumulator[group as keyof ConsentGroups] = 1;
      }
      return accumulator;
    }, groups);
  };

  // Registers onetrust consent change handler
  useEffect(() => {
    const id = setInterval(() => {
      if (window?.OneTrust && otSdkLoaded) {
        window.OneTrust.OnConsentChanged((event: any) => {
          const consentState = event?.detail ?? [];
          const consentGroups = getConsentGroups(consentState);
          // This logic should only be applied to bloomreach since
          // mparticle does not need this
          if (cdpProvider === CdpProviderTypes.Bloomreach) {
            handleBloomreachConsent(consentGroups, trackEvent);
          }
          setConsentGroups(consentGroups);
          LocalStorage.setItem(StorageKeys.ONETRUST_COOKIE_CONSENT_GROUPS, consentGroups);
        });
        setConsentGroupsSetInLocalStorage(true);
      }
    }, 1000);
    if (consentGroupsSetInLocalStorage) {
      clearInterval(id);
    }
    return () => clearInterval(id);
  }, [otSdkLoaded, consentGroupsSetInLocalStorage, cdpProvider, trackEvent]);

  // Pull the consent groups out of local storage if it's already populated.
  // This will trigger the hook below, and disable/enable any global services based on
  // the previously chosen settings.
  useEffect(() => {
    const existingConsentGroups = LocalStorage.getItem(StorageKeys.ONETRUST_COOKIE_CONSENT_GROUPS);
    if (existingConsentGroups) {
      setConsentGroups(existingConsentGroups);
    }
  }, []);

  // Switches off tracking for each service if data tracking is opted out
  useEffect(() => {
    const { cookieCompliance } = getConfigValue('cookieBanners').oneTrust;
    const {
      globalConsentState,
      consentCategoryMParticle,
      consentCategoryBraze,
      consentCategoryBranch,
      consentCategoryDdRum,
    } = cookieCompliance;

    // reads consent state for each consent category
    const cdpConsentState = otConsentGroups[consentCategoryMParticle as keyof ConsentGroups];
    const brazeConsentState = otConsentGroups[consentCategoryBraze as keyof ConsentGroups];
    const branchConsentState = otConsentGroups[consentCategoryBranch as keyof ConsentGroups];
    const datadogRumConsentState = otConsentGroups[consentCategoryDdRum as keyof ConsentGroups];
    const isCdpStrictlyNecessary = consentCategoryMParticle === OneTrustConsentCategories.C0001;

    // if consent state is opted out for respective service, data tracking is opted out
    // if the cdp provider is Bloomreach we should initialize it
    if (servicesLoaded) {
      if (cdpProvider === CdpProviderTypes.MParticle) {
        handleOTMparticleConsent(
          cdpConsentState,
          brazeConsentState,
          branchConsentState,
          globalConsentState,
          branchUnavailable,
          isCdpStrictlyNecessary
        );
      }

      const shouldUseSessionReplay = datadogRumConsentState === 1;
      if (shouldUseSessionReplay) {
        datadogRum.startSessionReplayRecording();
        datadogRum.startView();
      } else {
        datadogRum.stopSessionReplayRecording();
        datadogRum.stopSession();
      }
    }
  }, [otConsentGroups, servicesLoaded, branchUnavailable, cdpProvider]);

  return <div data-testid="one-trust-cookie-banner" />;
};

export const OneTrustCookieBannerMemo = React.memo(OneTrustCookieBanner);
