import React, { FC } from 'react';

import { ILoyaltyQrAndShortCodeModalContentFragment } from 'generated/sanity-graphql';

import { ModalContainer, SectionImage, StyledBlockRenderer } from './body.styled';

interface IModalBodyProps {
  data: ILoyaltyQrAndShortCodeModalContentFragment;
}

export const ModalBody: FC<IModalBodyProps> = ({ data }) => {
  return (
    <ModalContainer>
      {data.image && <SectionImage alt="" image={data.image} objectFitContain />}
      <StyledBlockRenderer content={data.details?.locale} />
    </ModalContainer>
  );
};
