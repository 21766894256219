import React, { FC, useCallback, useState } from 'react';

import { PrimaryButton, SecondaryButton } from '@rbilabs/components-library';
import { secondsToMilliseconds } from 'date-fns';
import { useIntl } from 'react-intl';

import { DialogMemo } from 'components/dialog';
import { LoadingAnimation } from 'components/loading-animation';
import { ButtonGroupsCancelDialog, TextCancelModal } from 'components/store-card/styled';
import { useExitQueueMutation, useGetWaitTimeQuery } from 'generated/graphql-gateway';
import { useStoreContext } from 'state/store';
import { logger } from 'utils/logger';
import { toast } from 'utils/toast';

import { QueueInformation } from './queue-information';
import {
  LoadingContainer,
  QueueDescription,
  QueueDescriptionContainer,
  Subtitle,
  Title,
} from './styled';
import { getEstimatedTimeMessage } from './utils';

interface IExitQueueArgs {
  storeId: string;
  orderId: string;
}

const GET_WAIT_TIME_POLLING_FREQUENCY = 30;

export const QueueModalContent: FC<{
  rbiOrderId: string;
}> = ({ rbiOrderId }) => {
  const { formatMessage, formatNumber, formatList } = useIntl();

  const formatHour = (hour: number) =>
    formatNumber(hour, { style: 'unit', unit: 'hour', unitDisplay: 'long' });
  const formatMinute = (minute: number) =>
    formatNumber(minute, { style: 'unit', unit: 'minute', unitDisplay: 'long' });
  const formatHourAndMinutesSkipZero = (hour: number, minute: number) =>
    formatList([hour && formatHour(hour), minute && formatMinute(minute)].filter(Boolean));

  const { store } = useStoreContext();
  const storeId = store.number || '';

  const [openExitQueueModal, setOpenExitQueueModal] = useState(false);

  const [executeExitQueueMutation] = useExitQueueMutation({
    onCompleted: response => {
      if (response.exitQueue.code === 'SUCCESS') {
        setOpenExitQueueModal(false);
        toast.success(formatMessage({ id: 'successfullyExitedQueue' }));
      } else {
        logger.error(`Error: Failed to exit queue for store ${storeId}.`);
        toast.error(formatMessage({ id: 'failedToExitQueue' }));
      }
    },
    onError: error => {
      throw error;
    },
  });

  const exitQueue = useCallback(
    async ({ storeId, orderId }: IExitQueueArgs) => {
      try {
        return executeExitQueueMutation({
          variables: {
            storeId,
            orderId,
          },
        });
      } catch (error) {
        logger.error({ error, message: 'Error updating order.' });
        throw error;
      }
    },
    [executeExitQueueMutation]
  );

  const onQueueExit = () => {
    setOpenExitQueueModal(true);
  };

  const handleCloseExitQueueModal = () => {
    setOpenExitQueueModal(false);
  };

  const handleSubmitExitQueueMutation = async () => {
    await exitQueue({
      storeId,
      orderId: rbiOrderId,
    });
  };

  const { data, loading } = useGetWaitTimeQuery({
    skip: !storeId,
    variables: {
      storeId: storeId || '',
      orderId: rbiOrderId,
    },
    pollInterval: secondsToMilliseconds(GET_WAIT_TIME_POLLING_FREQUENCY),
  });

  const waitTime = data?.waitTime
    ? { ...data.waitTime, position: data.waitTime.position + 1 } // display position starting from 1
    : undefined;
  const estimatedTime = getEstimatedTimeMessage({
    firingTimestamp: waitTime && new Date(waitTime.firingTimestamp),
    dateNow: new Date(),
    formatHourAndMinutesSkipZero,
  });

  if (loading) {
    return (
      <LoadingContainer>
        <LoadingAnimation fillColor={Styles.color.contrastBackground} />
      </LoadingContainer>
    );
  }

  return (
    <QueueDescriptionContainer>
      {waitTime && (
        <QueueDescription>
          <Title> {formatMessage({ id: 'virtualQueueTitle' })} </Title>

          <Subtitle> {formatMessage({ id: 'virtualQueueSubtitle' })} </Subtitle>
          <QueueInformation
            store={store}
            position={waitTime.position}
            firingTimestamp={waitTime.firingTimestamp}
            estimatedTime={estimatedTime}
            onQueueExit={onQueueExit}
          />
        </QueueDescription>
      )}
      {openExitQueueModal && (
        <DialogMemo
          data-testid="exit-queue-dialog"
          heading={formatMessage({ id: 'exitQueue' })}
          body={
            <ButtonGroupsCancelDialog>
              <TextCancelModal>{formatMessage({ id: 'exitQueueDialog' })}</TextCancelModal>
              <PrimaryButton
                fullWidth
                aria-label={formatMessage({ id: 'noExitQueueDialog' })}
                onClick={handleCloseExitQueueModal}
                data-testid="no-exit-queue-dialog-button"
              >
                {formatMessage({ id: 'noExitQueueDialog' })}
              </PrimaryButton>
              <SecondaryButton
                fullWidth
                aria-label={formatMessage({ id: 'yesExitQueueDialog' })}
                onClick={handleSubmitExitQueueMutation}
                data-testid="yes-exit-queue-dialog-button"
              >
                {formatMessage({ id: 'yesExitQueueDialog' })}
              </SecondaryButton>
            </ButtonGroupsCancelDialog>
          }
          modalAppearanceEventMessage="exit-queue-dialog"
        />
      )}
    </QueueDescriptionContainer>
  );
};

export const ModalContent = QueueModalContent;
