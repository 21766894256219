import { IOfferDiscount } from '@rbi-ctg/menu';
import { useLoyaltyRewardsList } from 'hooks/use-loyalty-rewards-list';
import { selectors, useAppSelector } from 'state/global-state';
import { getRewardPrice } from 'state/global-state/models/loyalty/rewards/rewards.utils';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { isDiscountReward } from 'state/loyalty/types';
import { isDiscountOffer } from 'state/loyalty/utils';
import {
  calculateDiscountValue,
  computeTotalWithOfferDiscount,
  getItemLoyaltyRewardDiscount,
} from 'utils/cart/helper';
import { priceForCartEntry } from 'utils/menu/price-for-cart-entry';

import { IPrice } from '../types';

export type UseCartItemPriceParams = Pick<IPrice, 'item' | 'isOffer' | 'offerId' | 'rewardApplied'>;

export const useCartItemPrice = ({
  item,
  isOffer,
  offerId,
  rewardApplied,
}: UseCartItemPriceParams) => {
  const loyaltyEnabled = useIsLoyaltyEnabled();
  const showPriceInsteadFreeInCart = useFlag(LaunchDarklyFlag.SHOW_PRICE_INSTEAD_FREE_IN_CART);
  const isAmountOfferDiscountValueAsCents = useFlag(
    LaunchDarklyFlag.ENABLE_AMOUNT_OFFER_DISCOUNT_VALUE_AS_CENTS
  );
  const appliedLoyaltyRewards = useAppSelector(selectors.loyalty.selectAppliedLoyaltyRewards);
  const appliedOffers = useAppSelector(selectors.loyalty.selectAppliedOffers);
  const cmsOffers = useAppSelector(selectors.loyalty.selectCmsOffers);
  const { sanityRewardsMap } = useLoyaltyRewardsList();

  const isRewardApplied = Boolean(rewardApplied && !isOffer);
  const showStrike = isRewardApplied && !showPriceInsteadFreeInCart;

  const appliedReward = appliedLoyaltyRewards[item.cartId];
  const sanityReward = appliedReward?.sanityId ? sanityRewardsMap?.[appliedReward.sanityId] : null;
  const rewardPrice =
    sanityRewardsMap && appliedReward?.sanityId
      ? getRewardPrice(sanityRewardsMap?.[appliedReward.sanityId])
      : false;
  const price = priceForCartEntry(item);

  let discountPrice =
    price -
    getItemLoyaltyRewardDiscount({
      cartEntry: item,
      appliedLoyaltyRewards,
      isAmountOfferDiscountValueAsCents,
    });

  // For discounted products we are calculating the discount only for the base item price
  // not including other options added by user when customizing reward item
  if (isDiscountReward(sanityReward)) {
    discountPrice =
      price -
      calculateDiscountValue(
        item.price!,
        sanityReward?.incentives?.[0] as IOfferDiscount,
        isAmountOfferDiscountValueAsCents
      );
  }

  if (rewardPrice) {
    const unitDiscount = item.price! / item.quantity - rewardPrice;
    discountPrice = price - unitDiscount * appliedReward.timesApplied;
  }

  if (isNaN(discountPrice)) {
    discountPrice = 0;
  }

  if (isOffer && offerId && appliedOffers) {
    const currentAppliedOffer = appliedOffers.find(offer => offer.id === offerId);
    const currentOffer = cmsOffers.find(offer => offer._id === currentAppliedOffer?.cmsId);

    if (currentOffer) {
      const isOfferDiscount = isDiscountOffer(currentOffer);
      const offerDiscount = currentOffer?.incentives?.[0] as IOfferDiscount;

      if (isOfferDiscount && offerDiscount) {
        const offerIncentivePrice = item.price!;

        const discountedAmount = computeTotalWithOfferDiscount(
          offerIncentivePrice,
          offerDiscount,
          isAmountOfferDiscountValueAsCents
        );

        discountPrice = discountedAmount;
      }
    }
  }

  return {
    price,
    showStrike,
    discountPrice,
    loyaltyEnabled,
    isRewardApplied,
    showPriceInsteadFreeInCart,
  };
};
