import * as React from 'react';
import { FC } from 'react';

import { useNavigate } from 'react-router-dom';

import { ActionsModal } from '../actions-modal';
import { TextAlign } from '../actions-modal/types';

import { useShortCodeConfirmationModal } from './use-short-code-confirmation-modal';

interface IShortCodeConfirmationModalProps {
  onAction: VoidFunction;
  onDismiss: VoidFunction;
}

export const ShortCodeConfirmationModal: FC<IShortCodeConfirmationModalProps> = ({
  onAction,
  onDismiss,
}) => {
  const navigate = useNavigate();
  const { data, loading } = useShortCodeConfirmationModal();
  const { actionButton, body, header, image, dismissButtonText } =
    data?.shortCodeConfirmationModalContent || {};
  const parsedImage = image?.locale?.app;

  const handleAction = () => {
    if (onAction) {
      onAction();
    }
    navigate(actionButton?.buttonPath?.locale || '');
  };

  if (loading || !data) {
    return null;
  }

  const actions = [
    {
      dataTestId: 'short-code-confirmation-action',
      actionLabel: actionButton?.buttonText?.locale || '',
      actionHandler: handleAction,
    },
    ...(dismissButtonText?.locale
      ? [
          {
            dataTestId: 'short-code-dismiss-action',
            actionLabel: dismissButtonText?.locale || '',
            actionHandler: onDismiss,
            textOnly: true,
          },
        ]
      : []),
  ];

  return (
    <ActionsModal
      actions={actions}
      bodyText={body?.locale || ''}
      data-testid="short-code-confirmation-modal"
      headerText={header?.locale || ''}
      headerTextAlign={TextAlign.CENTER}
      image={parsedImage}
      imageAlt="short-code-confirmation"
      modalAppearanceEventMessage="Loyalty Shortcode Confirmation Modal"
    />
  );
};
