import * as React from 'react';
import { FC } from 'react';

import { Icon } from '@rbilabs/components-library/build/components/icon';
import { useIntl } from 'react-intl';

import { IBaseProps } from '@rbi-ctg/frontend';
import { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';
import { ILocaleStringFragment } from 'generated/sanity-graphql';
import { useDialogModal } from 'hooks/use-dialog-modal';
import {
  ShortCodeState,
  useInRestaurantRedemptionContext,
} from 'state/loyalty/in-restaurant-redemption';
import { addStringInBetween } from 'utils/add-string-in-between';

import { RewardRedemptionListMemo } from '../../loyalty-in-restaurant-redemption/reward-redemption-list';

import {
  Code,
  Container,
  GenerateButton,
  IconContainer,
  Title,
} from './loyalty-short-code-widget.styled';

export interface ILoyaltyShortCodeWidgetProps extends IBaseProps {
  defaultCode: ILocaleStringFragment;
  title: ILocaleStringFragment;
  alternativeTitle: ILocaleStringFragment;
  subtitle: ILocaleStringFragment;
}

export const LoyaltyShortCodeWidget: FC<ILoyaltyShortCodeWidgetProps> = ({
  title,
  subtitle,
  alternativeTitle,
}) => {
  const { shortCodeState, shortCodeLoading, shortCode, generateShortCode } =
    useInRestaurantRedemptionContext();
  const { formatMessage } = useIntl();

  const [InfoDialog, openInfoDialog] = useDialogModal({
    modalAppearanceEventMessage: 'Short Code Info',
  });

  const generateCodeButton = (
    <GenerateButton
      variant={ActionButtonVariants.OUTLINE}
      data-testid="loyalty-short-code-generate-new-code"
      size={ActionButtonSizes.LARGE}
      perceptible
      onClick={generateShortCode}
      isLoading={shortCodeLoading}
      startIcon={<Icon icon={'refresh'} color={'primary'} width="20px" aria-hidden />}
    >
      {formatMessage({ id: 'generateNewCode' })}
    </GenerateButton>
  );

  return (
    <>
      <Container data-testid="short-code-widget">
        {shortCodeState === ShortCodeState.Pending && (
          <IconContainer data-testid="short-code-info" onClick={openInfoDialog}>
            <Icon icon={'info'} color={'tertiary'} width="24px" aria-hidden />
          </IconContainer>
        )}

        {shortCodeState === ShortCodeState.RequestError ? (
          <Title>{formatMessage({ id: 'codeErrorMessage' })}</Title>
        ) : (
          <>
            {shortCodeState === ShortCodeState.Expired ? (
              <Title>{alternativeTitle.locale}</Title>
            ) : (
              <Title>{title.locale}</Title>
            )}
          </>
        )}

        {shortCodeState === ShortCodeState.Pending && (
          <>
            {shortCode ? (
              <Code>{addStringInBetween(shortCode || '', 2, ' ')}</Code>
            ) : (
              generateCodeButton
            )}
          </>
        )}

        {shortCodeState === ShortCodeState.Expired && generateCodeButton}
      </Container>

      <RewardRedemptionListMemo loyaltyEmptyCartTileWidget={null} />
      <InfoDialog heading="" body={subtitle?.locale} />
    </>
  );
};
