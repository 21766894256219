// This mParticleAdapter is used as the adapter layer to talk to the mParticle web SDK
// Usage of window.mParticle should be strongly discouraged outside of this file

import { isArray, isEqual, isPlainObject } from 'lodash';

import { SKIP_EVENTS } from './constants';

export class CdpAdapterBase {
  prevEventName: string;
  prevEventAttr?: Record<string, unknown>;
  prevCustomFlags?: Record<string, unknown>;
  prevEventTimeLimit: number;

  /**
   * Compares the current event with the previous one.
   * @param name
   * @param attributes
   * @param customFlags
   * @returns If it is the same event or not.
   */
  sameEvent(
    name: string,
    attributes?: Record<string, unknown>,
    customFlags?: Record<string, unknown>
  ) {
    if (this.prevEventName !== name) {
      return false;
    } else if (
      this.sameObjects(attributes, this.prevEventAttr) &&
      this.sameObjects(customFlags, this.prevCustomFlags)
    ) {
      return true;
    }
    return false;
  }

  /**
   * Compares the current object with the previous one. It skips the attribute validation for time related ones.
   * @param currObject
   * @param prevObject
   * @returns If it is the same object or not.
   */
  sameObjects(currObject?: Record<string, unknown>, prevObject?: Record<string, unknown>) {
    if (typeof prevObject !== typeof currObject) {
      return false;
    } else if (!prevObject || !currObject) {
      return true;
    }
    const keys: Array<string> = Object.keys(prevObject);
    for (const key of keys) {
      if (
        (typeof prevObject[key] === 'string' &&
          /^\d{2}:\d{2}:\d{2}$/.test(prevObject[key] as string)) ||
        isArray(prevObject[key]) ||
        isPlainObject(prevObject[key])
      ) {
        continue;
      } else if (!currObject.hasOwnProperty(key) || !isEqual(prevObject[key], currObject[key])) {
        return false;
      }
    }
    return true;
  }

  /**
   * Validates if the current event needs to be skipped.
   * @param name
   * @param attributes
   * @param customFlags
   * @returns If the event should be skipped or not.
   */
  skipEvent(
    name: string,
    attributes?: Record<string, unknown>,
    customFlags?: Record<string, unknown>
  ) {
    if (SKIP_EVENTS.indexOf(name) === -1) {
      return false;
    }
    const isSameEvent: boolean = this.sameEvent(name, attributes, customFlags);
    if (isSameEvent) {
      if (new Date().getTime() > this.prevEventTimeLimit) {
        return false;
      }
      return true;
    }
    return false;
  }

  /**
   * Updates the previous event data.
   * @param name
   * @param attributes
   */
  updatePrevEvent(
    name: string,
    attributes?: Record<string, unknown>,
    customFlags?: Record<string, unknown>
  ) {
    this.prevEventName = name;
    this.prevEventAttr = attributes;
    this.prevCustomFlags = customFlags;
    this.prevEventTimeLimit = new Date().getTime() + 4000;
  }
}
