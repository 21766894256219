import styled from 'styled-components';

import { MarketingTileGroup as DefaultMarketingTileGroup } from 'components/features/components/marketing-tile-group';
import { BasicTileContainer, BasicTileTitle } from 'components/marketing-basic-tile/styled';
import { SanityBlockRenderer } from 'components/sanity-block-renderer';

export const RewardsTitle = styled.h1`
  color: ${Styles.color.primary};
  font-family: ${Styles.fontFamily.brand};
  font-size: 1.25rem;
  text-align: center;

  ${Styles.desktop} {
    font-size: 2.5rem;
  }
`;

export const DisclaimerMessage = styled(SanityBlockRenderer)`
  color: ${Styles.color.white};
  font-size: 0.75rem;
  margin-block-start: 0.75rem;
  margin-block-end: 0.9375rem;
  margin-inline: 0;
  text-align: center;

  ${Styles.desktop} {
    font-size: 0.93rem;
    margin-block-start: 1.5rem;
    margin-block-end: 3.125rem;
    margin-inline: 0;
  }
`;

export const MarketingTileGroup = styled(DefaultMarketingTileGroup)`
  margin: 0 1rem;
  border-radius: 0.563rem;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  & > :not(:last-child) {
    margin-block-end: 2rem;
  }

  ${BasicTileContainer} {
    background-color: transparent;
  }

  ${BasicTileTitle} {
    margin-block-end: 0.5rem;
  }
`;
