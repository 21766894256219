import * as React from 'react';
import { FC } from 'react';

import { ChooseLocation } from './actions/choose-location';
import {
  Button,
  ClickableWrapper,
  Container,
  Details,
  DetailsContainer,
  Heading,
  Icon,
} from './top-service-mode.styled';
import { ITopServiceModeViewProps } from './types';

export const TopServiceModeView: FC<ITopServiceModeViewProps> = ({
  heading,
  details,
  icon,
  button,
  onTopServiceClick,
}) => {
  return (
    <Container data-testid="top-service-mode">
      <ClickableWrapper data-testid="top-service-mode-wrapper" onClick={onTopServiceClick}>
        <Icon>{icon}</Icon>
        <DetailsContainer>
          <Heading>{heading}</Heading>
          {details ? (
            <Details>{details}</Details>
          ) : (
            <ChooseLocation data-testid="top-service-choose-location" />
          )}
        </DetailsContainer>
      </ClickableWrapper>
      {button && <Button>{button}</Button>}
    </Container>
  );
};
