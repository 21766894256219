import React from 'react';

interface IBurgerKingLogo {
  title?: string;
}

export const IconBurgerKingLogo: React.FC<IBurgerKingLogo> = ({
  title = 'Burger King Logo',
  ...props
}) => (
  <svg data-dir="ltr" {...props} viewBox="0 0 92 96" fill="none">
    <title>{title}</title>
    <path
      d="M45.9375 92C27.1827 92 16.8086 90.4542 10.0552 86.6305C5.0919 83.8237 2 79.5932 2 75.6068V22.6847C2 16.7458 6.35307 9.95254 13.3098 4.98983C19.0868 0.881356 29.461 -4 45.9375 -4C74.619 -4 89.8751 11.4983 89.8751 22.6847V22.9288V75.5661C89.8751 79.5932 86.7832 83.8237 81.7792 86.6305C75.0665 90.4542 64.6924 92 45.9375 92Z"
      fill="#F5EBDC"
    />
    <path
      d="M76.8429 80.6023C70.7842 84.0223 60.39 85 46.0118 85C31.6336 85 21.2395 84.0223 15.1808 80.6023C11.5443 78.5545 9.70361 75.7626 9.70361 73.761C9.70361 72.621 10.3101 71.9693 11.6606 71.9693H80.3617C81.7135 71.9693 82.3188 72.621 82.3188 73.761C82.32 75.7626 80.4793 78.5545 76.8429 80.6023ZM80.3617 27.5478H11.6606C10.3101 27.5478 9.70361 26.8499 9.70361 25.5937C9.70361 18.1469 20.9131 5 46.0118 5C70.878 5 82.32 18.1469 82.32 25.5937C82.32 26.8499 81.7135 27.5478 80.3617 27.5478Z"
      fill="#FF8732"
    />
    <path
      d="M24.3381 58.2642C24.128 57.9383 24.1055 57.7285 24.1055 57.6361C24.1055 57.3802 24.2455 57.1941 24.5244 56.9382C25.1771 56.3326 26.7853 54.8205 27.6706 53.4482C28.3459 52.4006 28.626 51.4467 28.626 50.6563C28.626 48.6322 26.7853 47.049 24.7807 47.049C23.499 47.049 22.2872 47.7707 21.4719 49.0967C20.3765 50.8648 19.071 52.9126 18.2331 53.7279C18.023 53.9377 17.9068 53.9839 17.7442 53.9839C17.4641 53.9839 17.3478 53.7978 17.3478 53.4957V51.1682C17.3478 48.8882 15.996 47.049 13.5488 47.049C11.079 47.049 9.74976 48.887 9.74976 51.1682V65.5025C9.74976 67.7825 11.0778 69.6218 13.5488 69.6218C15.996 69.6218 17.3478 67.7838 17.3478 65.5025V62.1987C17.3478 61.919 17.4641 61.7104 17.7442 61.7104C18.1168 61.7104 18.2106 62.0825 18.3032 62.2686C18.8396 63.5484 20.4002 66.2705 21.8458 67.8762C22.825 68.9463 24.0367 69.6218 25.3185 69.6218C27.4856 69.6218 29.3038 67.7364 29.3038 65.7123C29.3038 64.4562 28.7673 63.5247 27.7419 62.4084C26.4364 60.9862 24.8983 59.1245 24.3381 58.2642Z"
      fill="#D62300"
    />
    <path
      d="M53.3997 45.7217C57.0587 45.7217 59.3658 43.3942 59.3658 39.9979C59.3658 37.834 58.0603 36.3444 55.4505 36.3444H54.7052C53.3297 36.3444 52.258 36.9962 52.258 38.4621C52.258 39.7657 53.3997 40.3937 54.2625 40.3937C54.7052 40.3937 54.9853 40.5336 54.9853 40.8595C54.9853 41.2778 54.4964 41.5575 53.9362 41.5575C52.188 41.5575 51 40.3238 51 38.0201C51 35.4604 52.6319 34.5302 53.8899 34.5302C55.428 34.5302 55.6844 35.3218 56.756 35.3218C57.9915 35.3218 58.8306 34.2979 58.8306 33.2278C58.8306 32.4362 58.4805 31.8319 57.9453 31.4123C56.9661 30.6906 55.5918 30.1562 53.541 30.1562C49.9283 30.1562 46.4094 32.5298 46.4094 38.0213C46.4082 43.2781 49.9271 45.7217 53.3997 45.7217Z"
      fill="#D62300"
    />
    <path
      d="M12.4297 45.5819H15.716C18.816 45.5819 20.703 43.5104 20.703 40.9282C20.703 38.8567 19.4675 37.9028 19.1886 37.6705C19.0723 37.5769 18.956 37.4845 18.956 37.3908C18.956 37.2747 19.0261 37.2285 19.1424 37.0886C19.585 36.5767 20.0277 35.7626 20.0277 34.5751C20.0277 31.7832 18.1395 30.2936 15.2033 30.2936H12.4297C10.589 30.2936 9.70361 31.3649 9.70361 32.8071V43.0684C9.70361 44.5118 10.589 45.5819 12.4297 45.5819ZM13.8052 34.2967C13.8052 33.9945 13.9916 33.761 14.388 33.761H14.6906C15.6922 33.761 15.9961 34.459 15.9961 35.0646C15.9961 35.6227 15.6935 36.4144 14.6906 36.4144H14.388C13.9916 36.4144 13.8052 36.1821 13.8052 35.8787V34.2967ZM13.8052 39.742C13.8052 39.4398 13.9916 39.2063 14.388 39.2063H14.8069C15.9261 39.2063 16.2512 39.9505 16.2512 40.626C16.2512 41.2316 15.9248 42.0919 14.8069 42.0919H14.388C13.9916 42.0919 13.8052 41.8597 13.8052 41.5563V39.742Z"
      fill="#D62300"
    />
    <path
      d="M73.2776 45.698C74.7695 45.698 75.5848 44.5804 75.5848 43.1845V40.7184C75.5848 40.4162 75.7249 40.2764 75.9574 40.2764C76.2376 40.2764 76.3301 40.4387 76.3764 40.7184C76.5864 41.9046 77.1454 43.5103 77.7744 44.3257C78.5672 45.3733 79.3125 45.6992 80.0578 45.6992C81.2696 45.6992 82.3187 44.7452 82.3187 43.5578C82.3187 42.7662 81.9923 42.1843 81.5734 41.6499C80.7806 40.6497 80.2917 39.9979 79.989 39.1838C79.919 38.9978 79.9653 38.8342 80.1754 38.6719C80.8982 38.1137 81.7372 36.8801 81.7372 34.926C81.7372 31.9705 79.29 30.2948 76.3301 30.2948H73.6965C71.8558 30.2948 70.9705 31.3649 70.9705 32.8083V43.1869C70.9705 44.5817 71.7858 45.698 73.2776 45.698ZM75.3284 34.6925C75.3284 34.3903 75.5148 34.1568 75.9112 34.1568H76.2376C77.2167 34.1568 77.5193 34.8548 77.5193 35.4604C77.5193 36.0423 77.2167 36.8102 76.2376 36.8102H75.9112C75.5148 36.8102 75.3284 36.5779 75.3284 36.2745V34.6925Z"
      fill="#D62300"
    />
    <path
      d="M34.965 47.0478C32.3552 47.0478 30.9333 49.0019 30.9333 51.4454V65.2216C30.9333 67.6652 32.3552 69.6193 34.965 69.6193C37.5747 69.6193 38.9966 67.6652 38.9966 65.2216V51.4454C38.9966 49.0031 37.576 47.0478 34.965 47.0478Z"
      fill="#D62300"
    />
    <path
      d="M76.4001 56.0067H75.2584C73.2076 56.0067 71.6232 56.9607 71.6232 59.0546C71.6232 60.9625 73.3476 61.8703 74.6294 61.8703C75.2584 61.8703 75.7011 62.0563 75.7011 62.5445C75.7011 63.1501 74.9557 63.5447 74.1629 63.5447C71.5294 63.5447 69.7349 61.7529 69.7349 58.4253C69.7349 54.7256 72.1821 53.3758 74.0691 53.3758C76.3763 53.3758 76.749 54.5396 78.3571 54.5396C80.2216 54.5396 81.4796 53.0275 81.4796 51.4916C81.4796 50.3516 80.9669 49.4676 80.1516 48.862C78.7298 47.8144 76.5864 47.0465 73.5564 47.0465C68.1268 47.0465 62.8359 50.4665 62.8359 58.4253C62.8359 66.0345 68.1255 69.5943 73.3464 69.5943C78.8223 69.5943 82.2949 66.2205 82.2949 61.2872C82.2962 58.1706 80.3391 56.0067 76.4001 56.0067Z"
      fill="#D62300"
    />
    <path
      d="M67.5918 45.5819C68.8973 45.5819 69.9227 44.814 69.9227 43.418C69.9227 42.0445 68.8973 41.2766 67.5918 41.2766H65.4947C65.0983 41.2766 64.9119 41.0443 64.9119 40.7409V40.2989C64.9119 39.9967 65.0983 39.7632 65.4947 39.7632H67.1028C68.222 39.7632 69.1074 39.089 69.1074 37.9015C69.1074 36.7153 68.222 36.0398 67.1028 36.0398H65.4947C65.0983 36.0398 64.9119 35.8075 64.9119 35.5041V35.132C64.9119 34.8299 65.0983 34.5964 65.4947 34.5964H67.5918C68.8973 34.5964 69.9227 33.8285 69.9227 32.4325C69.9227 31.059 68.8973 30.2911 67.5918 30.2911H63.1875C61.3468 30.2911 60.4614 31.3612 60.4614 32.8046V43.0659C60.4614 44.508 61.3468 45.5794 63.1875 45.5794H67.5918V45.5819Z"
      fill="#D62300"
    />
    <path
      d="M27.5544 45.698C31.3297 45.698 33.3342 43.4404 33.3342 40.6023V32.7609C33.3342 31.3649 32.5189 30.2474 31.027 30.2474C29.5352 30.2474 28.7199 31.3649 28.7199 32.7609V40.3463C28.7199 40.9045 28.3935 41.5563 27.5544 41.5563C26.7153 41.5563 26.4352 40.9045 26.4352 40.3463V32.7609C26.4352 31.3649 25.5961 30.2474 24.1043 30.2474C22.6124 30.2474 21.7971 31.3649 21.7971 32.7609V40.6023C21.7984 43.4417 23.7792 45.698 27.5544 45.698Z"
      fill="#D62300"
    />
    <path
      d="M43.9148 45.698C45.1265 45.698 46.1757 44.744 46.1757 43.5566C46.1757 42.7649 45.8493 42.1831 45.4304 41.6487C44.7551 40.7646 44.1486 39.9967 43.846 39.1826C43.776 38.9966 43.8222 38.833 44.0323 38.6707C44.7551 38.1125 45.5942 36.8789 45.5942 34.9248C45.5942 31.9692 43.147 30.2936 40.1871 30.2936H37.5523C35.7115 30.2936 34.8262 31.3637 34.8262 32.8071V43.1857C34.8262 44.5817 35.6415 45.6992 37.1333 45.6992C38.6252 45.6992 39.4405 44.5817 39.4405 43.1857V40.7197C39.4405 40.4175 39.5806 40.2777 39.8132 40.2777C40.0933 40.2777 40.1858 40.44 40.2321 40.7197C40.4422 41.9059 41.0011 43.5116 41.6301 44.327C42.4229 45.3721 43.1682 45.698 43.9148 45.698ZM40.092 36.809H39.7656C39.3692 36.809 39.1829 36.5767 39.1829 36.2733V34.6925C39.1829 34.3903 39.3692 34.1569 39.7656 34.1569H40.092C41.0712 34.1569 41.3738 34.8548 41.3738 35.4604C41.3738 36.041 41.0712 36.809 40.092 36.809Z"
      fill="#D62300"
    />
    <path
      d="M57.3139 47.0478C54.913 47.0478 53.6087 48.8395 53.6087 51.0971V56.1228C53.6087 56.5411 53.4686 56.7284 53.2123 56.7284C53.026 56.7284 52.8859 56.6123 52.7233 56.2864L49.4845 50.0033C48.2491 47.6059 46.9673 47.0478 45.4992 47.0478C43.052 47.0478 41.7002 48.8858 41.7002 51.1895V65.5712C41.7002 67.8288 43.0057 69.6206 45.4054 69.6206C47.8064 69.6206 49.1107 67.8288 49.1107 65.5712V60.5455C49.1107 60.1272 49.2507 59.9399 49.5071 59.9399C49.6934 59.9399 49.8334 60.056 49.996 60.3819L53.2348 66.665C54.4703 69.0624 55.7983 69.6206 57.2201 69.6206C59.6674 69.6206 61.0191 67.7826 61.0191 65.4788V51.0971C61.0191 48.8395 59.7149 47.0478 57.3139 47.0478Z"
      fill="#D62300"
    />
  </svg>
);
