import React, { forwardRef } from 'react';

import { Icon } from '@rbilabs/components-library/build/components/icon';
import { getThemeColor } from '@rbilabs/components-library/build/utils';

import { theme } from 'styles/configure-theme';

import { Container, Label } from './clickable-text-with-pencil.styled';
import { IClickableTextWithPencilProps } from './types';

export const ClickableTextWithPencil = forwardRef<HTMLButtonElement, IClickableTextWithPencilProps>(
  ({ onClick, label, color = 'icon-default', arialLabel }, ref) => {
    return (
      <Container
        ref={ref}
        data-testid="clickable-text-with-pencil"
        color={getThemeColor(theme, color)}
        onClick={onClick}
        aria-label={arialLabel}
      >
        <Label>{label}</Label>
        <Icon title={label} icon="editWithEraser" width="13px" color={color} />
      </Container>
    );
  }
);
