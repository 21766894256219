import React, { useCallback } from 'react';

import { Box } from '@rbilabs/components-library/build/components/layout';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { ICartEntry } from '@rbi-ctg/menu';
import { ActionButton, ActionLink } from 'components/action-button';
import { Modal, ModalContent, ModalHeading, ModalSize } from 'components/modal';
import { Picture } from 'components/picture';
import { useOrderContext } from 'state/order';
import { useStoreContext } from 'state/store';
import { routes } from 'utils/routing';

import { theme } from './theme';

export const Content = styled(ModalContent)`
  > div {
    justify-content: space-between;
    padding-block-end: 2rem;
    ${theme.contentPadding && `padding: ${theme.contentPadding};`}
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: auto;

  ${Styles.desktop} {
    max-width: 500px;
    min-width: none;
  }
`;

export const Header = styled(ModalHeading)`
  max-width: ${theme.contentWidth};
  ${Styles.desktop} {
    max-width: 500px;
  }
  margin-block-end: 10px;
`;

export const ModalMessage = styled.p`
  max-width: ${theme.contentWidth};
  ${Styles.desktop} {
    max-width: 500px;
  }
  text-align: center;
`;

export const ItemContainer = styled.div`
  max-width: ${theme.contentWidth};
  ${Styles.desktop} {
    max-width: 500px;
  }
  margin-block-start: ${theme.itemContainerMarginTop};
  background-color: ${theme.itemBackground};
  border-radius: ${theme.itemBorderRadius};
`;

export const Item = styled.div`
  display: flex;
  padding: ${theme.itemPadding};

  &:not(:first-child) {
    border-block-start: ${theme.itemBorder};
  }
`;

export const TileImageWrapper = styled.div`
  height: 80px;
  position: relative;
  width: 80px;
  border-radius: 10px;
  background-color: ${theme.itemImageBackgroundColor};
  flex-shrink: 0;
  margin-inline-end: 1.125rem;
`;

export const ItemName = styled.p`
  font-family: ${theme.itemNameFont};
`;

interface IProps {
  items: ICartEntry[];
  onDismiss: () => void;
}

export const ModalOrderUnavailable = ({ items, onDismiss }: IProps) => {
  const { formatMessage } = useIntl();
  const { emptyCart, selectServiceMode, removeAllFromCart, setUnavailableCartEntries } =
    useOrderContext();
  const { resetStore } = useStoreContext();

  const handleRemoveUnavailableItems = useCallback(() => {
    removeAllFromCart(items);
    setUnavailableCartEntries([]);
    onDismiss();
  }, [items, removeAllFromCart, onDismiss, setUnavailableCartEntries]);

  const handleStartOver = useCallback(() => {
    selectServiceMode(null);
    setUnavailableCartEntries([]);
    emptyCart();
    resetStore();
    onDismiss();
  }, [onDismiss, selectServiceMode, emptyCart, resetStore, setUnavailableCartEntries]);

  const modalHeading = formatMessage({ id: 'itemsUnavailable' });
  const modalBody = formatMessage({ id: 'someItemsAreUnavailableMessage' });

  return (
    <Modal
      allowsDismiss={false}
      onDismiss={handleStartOver}
      size={ModalSize.REGULAR}
      eventData={{
        modalAppearanceEventMessage: 'Items are unavailable',
        modalMessage: modalBody,
      }}
    >
      <Content>
        <Container>
          <Header id="modal-heading" data-testid="heading">
            {modalHeading}
          </Header>
          <ModalMessage data-testid="body">{modalBody}</ModalMessage>

          <ItemContainer>
            {items.map((item, index) => (
              <>
                <Item key={`${item.cartId}-${index}`}>
                  {item.image && (
                    <TileImageWrapper>
                      <Picture alt="" image={item.image} />
                    </TileImageWrapper>
                  )}
                  <ItemName>{item.name}</ItemName>
                </Item>
              </>
            ))}
          </ItemContainer>
          <Box
            margin={theme.buttonContainerMargin}
            flexDirection="column"
            width="100%"
            minWidth="auto"
          >
            <Box margin="0.5rem 0" width="100%" minWidth="auto">
              <ActionButton onlyIcon fullWidth onClick={handleRemoveUnavailableItems}>
                {formatMessage({ id: 'removeItems' })}
              </ActionButton>
            </Box>
            <Box margin="0.5rem 0" width="100%" minWidth="auto">
              <ActionLink
                onlyIcon
                fullWidth
                variant={theme.emptyCartBtnVariant}
                to={routes.menu}
                onClick={handleStartOver}
              >
                {formatMessage({ id: 'emptyCart' })}
              </ActionLink>
            </Box>
          </Box>
        </Container>
      </Content>
    </Modal>
  );
};
